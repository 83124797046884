const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export const images = {
  lightImg: `${PUBLIC_CDN}/pwa_dist/images/ccc-campaigns/light-27df2771d6.png`,
  couponItemBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/coupon-item-bg-ea8f61ca02.png`,
  romweCouponItemBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/romwe-coupon-item-bg-a0ce0be5ec.png`,
  suspensionBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/suspension-bg-7bf62c2dac.png`,
  suspensionMultiCoupon: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/suspension-multi-coupon-6209ad2895.png`,
  suspensionSingleCoupon: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/suspension-single-coupon-8be0a81445.png`,
  countdownSuspensionBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/countdown-suspension-bg-4f723fea4a.png`,
  romweSuspensionMultiCoupon: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/romwe-suspension-multi-coupon-9e2c55537a.png`,
  romweSuspensionSingleCoupon: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/romwe-suspension-single-coupon-547a1201a8.png`,
  arSuspensionBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/ar-suspension-bg-e3ef20507c.png`,
  arCountdownSuspensionBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/entrance-leave-dialog/ar-countdown-suspension-bg-739d307487.png`,
}
