<template>
  <div
    :class="['lottery-banner', prizeType !== 12 ? 'not-card' : 'is-card']"
    :style="{ backgroundImage: `url(${ prizeType !== 12 ? images.lotteryBannerBgSmall : images.lotteryBannerBgBig })` }"
  >
    <div class="lottery-banner__title">
      {{ prizeType === 5 ? shareLanguage.SHEIN_KEY_PWA_28615 : shareLanguage.SHEIN_KEY_PWA_25740 }}
    </div>
    <BaseImg
      :img-src="prizeDetail.prizeImg"
      :placeholder="{
        width: 92,
        height: 92,
      }"
      :img-design-width="184"
      fit="cover"
      class="lottery-banner__prize-img"
    />
    <CopyCard
      v-if="prizeType === 12"
      :card-code="prizeDetail.voucherCode"
      :card-cipher="prizeDetail.voucherCipher"
      class="lottery-banner__copy"
      @copy="handleCardCopy"
    />
    <div
      class="lottery-banner__jump"
      @click="handleJump"
    >
      {{ jumpText }}
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import CopyCard from './CopyCard.vue'
const { langPath = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'ShareLotteryBanner',
  components: {
    BaseImg,
    CopyCard,
  },
  inject: ['shareLanguage'],
  props: {
    userWinPrize: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      images
    }
  },
  computed: {
    // 奖品信息
    prizeDetail({ userWinPrize }) {
      console.log(JSON.parse(userWinPrize?.extendInfo || '{}'), '====prizeDetail===')
      return JSON.parse(userWinPrize?.extendInfo || '{}')
    },
    // 奖品类型 1: 优惠券、2: 积分、3: 购物卡、 4: 实物、5: 谢谢惠顾, 12: 礼品卡
    prizeType({ userWinPrize }) {
      return Number(userWinPrize?.prizeType) || 1
    },
    jumpText() {
      let text = ''
      if (this.prizeType === 1) text = `${this.shareLanguage.SHEIN_KEY_PWA_25738} >`
      if (this.prizeType === 2) text = `${this.shareLanguage.SHEIN_KEY_PWA_25737} >`
      if (this.prizeType === 12) text = `${this.shareLanguage.SHEIN_KEY_PWA_28612} >`
      return text
    }
  },
  methods: {
    handleCardCopy() {},
    handleJump() {
      // 优惠券列表
      this.$emit('close')
      if (this.prizeType === 1) this.$router.push(`${langPath}/user/coupon`)
      // 积分列表
      if (this.prizeType === 2) this.$router.push(`${langPath}/user/points`)
      // 礼品卡列表
      if (this.prizeType === 12) this.$router.push(`${langPath}/user/gift_card`)
    },
  }
}
</script>

<style lang="less" scoped>
.lottery-banner {
  width: 9.36rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0.4267rem auto 0;
  padding: 0.4rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &.not-card {
    height: 4.6933rem;
  }
  &.is-card {
    height: 6.48rem;
  }
  &__title {
    color: #fff;
    font-size: 0.3733rem;
    font-weight: 700;
    width: 80%;
    line-height: 0.4267rem;
  }
  &__prize-img {
    width: 2.4533rem;
    height: 2.4533rem;
  }
  &__jump {
    font-size: 0.32rem;
    color: #fff;
    font-weight: 400;
    line-height: 1;
  }
}
</style>
