<template>
  <div>
    <!-- 入场弹窗 -->
    <EntranceDialog
      ref="entranceDialogRef"
      :context="context"
      :scene-data="sceneData"
      :entrance-data="entranceData"
      @showBottomSuspension="handleBottomSuspension"
      @showEntranceDialog="showEntranceDialog"
      @closeDialog="closeDialog"
    />
    <!-- 离开弹窗 -->
    <LeaveDialog
      v-if="enableLeaveDialog"
      ref="leaveDialogRef"
      :context="context"
      :scene-data="sceneData"
      :cate-links="cateLinks"
      @showBottomSuspension="handleBottomSuspension"
      @showLeaveDialog="showLeaveDialog"
      @closeDialog="closeDialog"
    />
    <!-- 悬浮底部 -->
    <BottomSuspension
      v-bottom-sticky="{
        isAllowFloat: showBottomSuspension,
        styleType: 'MODALS_COUPON_FLOAT',
      }"
      :show="showBottomSuspension"
      :dialog-type="dialogType"
      :item="couponInfo"
      :is-multi-coupon="isMultiCoupon"
      :is-romwe="context.isRomwe"
      :show-bottom-tab="showBottomTab"
      @close="closeBottomSuspension"
    />
  </div>
</template>

<script>
import LeaveDialog from './leaveDialog.vue'
import EntranceDialog from './entranceDialog.vue'
import BottomSuspension from './bottomSuspension.vue'
import { getCccCouponDetailsApi, queryCouponDetailAndStatusApi } from 'public/src/services/api/ccc-coupon'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { bottomSticky } from 'public/src/pages/components/ccc/base/sticky/bottom.js'
export default {
  name: 'EntranceLeaveDialog',
  components: {
    LeaveDialog,
    EntranceDialog,
    BottomSuspension,
  },
  directives: { bottomSticky },
  props: {
    context: {
      type: Object,
      default: () => {}
    },
    sceneData: {
      type: Object,
      default: () => {}
    },
    cateLinks: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showBottomSuspension: false,
      dialogType: '',
      couponInfo: {},
      isMultiCoupon: false,
      availableCouponCodeList: [], // 可用的优惠券
      unbindCouponCodeList: [], // 没有被绑定的优惠券
      entranceData: {}, // 入场弹窗数据
      showDialog: false,
    }
  },
  computed: {
    showBottomTab() {
      return this.context.content?.bottomTabConfig?.length >= 3
    },
    // 弹窗多语言
    dialogLanguage() {
      const { entranceLeaveDialog = {} } = this?.context?.language || {}
      return entranceLeaveDialog
    },
    mountedData() {
      return this.context?.content?.extendParse?.modals?.mounted || {}
    },
    beforeLeaveData() {
      return this.context?.content?.extendParse?.modals?.beforeLeave || {}
    },
    entranceCouponCodesList({ mountedData }) {
      const couponItems = mountedData?.couponItems || []
      return couponItems.map(i => i.code?.toLowerCase())
    },
    leaveCouponCodesList({ beforeLeaveData }) {
      const couponItems = beforeLeaveData?.couponItems || []
      return couponItems.map(i => i.code?.toLowerCase())
    },
    enableEntranceDialog({ mountedData }) {
      return mountedData?.enable
    },
    enableLeaveDialog({ beforeLeaveData }) {
      return beforeLeaveData?.enable
    },
    enableEntranceFloat({ mountedData, entranceCouponCodesList = [] }) {
      // enableFloatDisplayAfterReceive 后台默认返回true
      const { enable = false, enableFloatDisplayAfterReceive = false, modalType = '' } = mountedData || {}
      return enable && enableFloatDisplayAfterReceive && +modalType === 2 && entranceCouponCodesList?.length
    },
    enableLeaveFloat({ beforeLeaveData, leaveCouponCodesList = [] }) {
      // enableFloatDisplayAfterReceive 后台默认返回true
      const { enable = false, enableFloatDisplayAfterReceive = false, modalType = '' } = beforeLeaveData || {}
      return enable && enableFloatDisplayAfterReceive && +modalType === 2 && leaveCouponCodesList?.length
    },
  },
  provide() {
    return {
      language: this.dialogLanguage,
      isRomwe: this.context?.isRomwe,
      GB_cssRight: this.context?.GB_cssRight || false,
    }
  },
  watch: {
    showDialog(val) {
      if (val) this.closeBottomSuspension()
    }
  },
  async mounted() {
    // 未配置钩子弹窗 || 钩子弹窗没有可用的优惠券 && 挽留弹窗有可用的优惠券的情况下，一进页面要展示悬浮底部
    this.initData()
  },
  methods: {
    async initData() {
      if (isLogin()) {
        if (this.enableEntranceFloat) {
          await this.getCouponDetailAndStatus(this.entranceCouponCodesList, 'entrance')
          if (!this.availableCouponCodeList.length && !this.unbindCouponCodeList.length && this.enableLeaveFloat) {
            await this.getCouponDetailAndStatus(this.leaveCouponCodesList, 'leave')
          }
        } else if (this.enableLeaveFloat) {
          await this.getCouponDetailAndStatus(this.leaveCouponCodesList, 'leave')
        }
      }
    },
    async handleBottomSuspension(item = {}) {
      const { enableFloatDisplayAfterReceive: enableMounted } = this.mountedData
      const { enableFloatDisplayAfterReceive: enableLeave } = this.beforeLeaveData
      if (!enableMounted && !enableLeave) return
      if (this.showDialog) return
      const { dialogType = '', availableCouponCodeList = [] } = item
      this.dialogType = dialogType
      // 有可能其中有一个弹窗是积分或者图文，关闭弹窗后要重新查下优惠券
      if (!availableCouponCodeList.length) {
        await this.initData()
      } else {
        // 两个弹窗悬浮开关一开一关的情况
        if (dialogType === 'entrance' && !enableMounted || dialogType === 'leave' && !enableLeave) {
          const codeList = dialogType === 'entrance' ? this.leaveCouponCodesList : this.entranceCouponCodesList
          const type = dialogType === 'entrance' ? 'leave' : 'entrance'
          await this.getCouponDetailAndStatus(codeList, type)
        } else { // 挽留和钩子悬浮都开的情况
          // 按B端配置的顺序给状态接口返回的数据重新排序
          let codeList = []
          if (dialogType === 'entrance') codeList = this.entranceCouponCodesList
          if (dialogType === 'leave') codeList = this.leaveCouponCodesList
          const availableList = codeList.filter(i => {
            if(availableCouponCodeList.includes(i)) return i
          })
          const list = await this.getCouponDetail(availableList)
          this.handleCouponData(list)
        }
      }
    },
    // 筛选出可用的第一张优惠券
    handleCouponData(list) {
      if (this.showDialog) return
      this.couponInfo = list.length && list.find(item => {
        item.startTime = +item?.startTime || 0
        item.endTime = +item?.endTime || 0
        item.dynamicExpiredDate = +item?.dynamicExpiredDate || 0
        const currentTime = Math.ceil(new Date().getTime() / 1000) // 转化成秒
        const bindTime = +item?.couponBind?.start_time || 0
        const { startTime = 0, endTime = 0, dynamicExpiredDate = 0 } = item
        if (dynamicExpiredDate) { // 动态有效期
          const dynamicTime = dynamicExpiredDate * 24 * 60 * 60
          // 当前时间大于绑定时间 + 动态有效期时间，不展示
          return (currentTime > startTime && (bindTime + dynamicTime > currentTime)) === true
        } else {
          // 当前时间小于开始时间或者大于结束时间，不展示
          return (currentTime > startTime && currentTime < endTime) === true
        }
      }) || {}
      if (Object.keys(this.couponInfo).length) this.showBottomSuspension = true
    },
    closeBottomSuspension() {
      this.showBottomSuspension = false
    },
    showLeaveDialog() {
      this.showDialog = true
      this.closeBottomSuspension()
    },
    showEntranceDialog() {
      this.showDialog = true
      this.closeBottomSuspension()
    },
    closeDialog() {
      this.showDialog = false
    },
    async getCouponDetailAndStatus(codeList = [], type = 'entrance') {
      this.unbindCouponCodeList = []
      this.availableCouponCodeList = []
      const detailParams = {
        codes: codeList,
        requestType: 'couponDetails',
        extra_fields: ['coupon_bind'],
      }
      const statusParams = {
        couponCodeList: codeList,
        requestType: 'couponStatus',
        queryUserStatusFlag: true,
        filterBindableFlag: false,
      }
      const result = await queryCouponDetailAndStatusApi({
        detailParams,
        statusParams,
        flag: 'all',
      })
      const { detailData = [], statusData = {} } = result || {}
      // if (type === 'entrance') this.entranceData = result
      const { code = '', data = {} } = statusData
      if (code === '1000') {
        // 按B端配置的顺序给状态接口返回的数据重新排序
        let statusObj = {}
        codeList.forEach(item => {
          Object.keys(data)?.forEach(key => {
            if (item === key.toLowerCase()) statusObj[item] = data[item]
          })
        })
        Object.keys(statusObj).forEach(key => {
          const item = statusObj[key]
          // bindStatus 绑定状态 0未绑定 1已绑定
          // useStatus 可用状态 0不可用 1可用
          if (item?.bindStatus === 0) {
            this.unbindCouponCodeList.push(key)
          }
          if (item?.bindStatus === 1 && item?.useStatus === 1) {
            this.availableCouponCodeList.push(key)
            return
          }
        })
        // 有未绑定的券，但是未绑定的券已过期，过滤掉过期的券
        const allCouponNotExpired = detailData.every(item => {
          return this.unbindCouponCodeList?.includes(item.couponCode?.toLowerCase())
        })
        // 钩子弹窗有未绑定的优惠券时会弹窗，不展示悬浮底部
        if (type === 'entrance' && this.unbindCouponCodeList.length && allCouponNotExpired) return
        const list = this.availableCouponCodeList.length && detailData?.filter(i => {
          if(this.availableCouponCodeList.includes(i.couponCode)) return i
        }) || []
        this.handleCouponData(list)
      }
    },
    // 获取优惠券详情
    async getCouponDetail(codes) {
      const params = {
        codes,
        requestType: 'couponDetails',
        extra_fields: ['coupon_bind'],
      }
      try {
        const result = await getCccCouponDetailsApi(params)
        return result || []
      } catch(e) {
        console.log(e)
      }
    },
  }
}
</script>
