<template>
  <div
    class="share-banner"
    :style="{ backgroundImage: `url(${extendData.shareHeadImgUrl.src})` }"
  >
    <!-- 已领取背景加蒙层 -->
    <div
      v-if="shareStatus"
      class="share-banner__got-bg"
    >
    </div>
    <!-- 已领取右上角加icon-->
    <div
      v-if="shareStatus"
      class="share-banner__got-icon"
    >
      <Icon
        name="sui_icon_select_selected_16px"
        size="0.56rem"
        color="#fff"
      />
    </div>
    <div class="share-banner__content">
      <div
        class="share-banner__user"
        :style="{ backgroundImage: `url(${images.userShare})` }"
      >
        <p class="share-banner__user-text">
          {{ shareLanguage.SHEIN_KEY_PWA_22421 }}
        </p>
      </div>
      <div class="share-banner__arrow">
        <img
          :src="images.shareBgArrow"
          alt=""
        />
      </div>
      <div
        class="share-banner__prize"
        :style="{ backgroundImage: prizeStyle.backgroundImage }"
      >
        <!-- 优惠券 -->
        <div
          v-if="prizeType === 1"
          class="share-banner__prize-coupon"
        >
          <!-- 免邮券 -->
          <template v-if="couponType === 1">
            <OutlineText
              :config="outlineConfig"
              :text="shareLanguage.SHEIN_KEY_PWA_22801"
              font-size="22"
              class="free-shipping"
            />
          </template>
          <!-- 满减券 -->
          <template v-if="couponType === 2">
            <div class="fill-discount">
              <OutlineText
                :config="outlineConfig"
                :text="couponDiscountValue"
                font-size="26"
                class="amount"
              />
              <OutlineText
                :config="outlineConfig"
                :text="shareLanguage.SHEIN_KEY_PWA_25739"
                font-size="26"
                class="off"
              />
            </div>
          </template>
          <!-- 折扣券 -->
          <template v-if="couponType === 3">
            <div class="discount-coupon">
              <div class="num">
                <OutlineText
                  :config="outlineConfig"
                  :text="couponDiscountValue"
                  font-size="42"
                />
                <OutlineText
                  :config="outlineConfig"
                  text="%"
                  font-size="26"
                  class="off"
                />
              </div>
              <OutlineText
                :config="outlineConfig"
                :text="shareLanguage.SHEIN_KEY_PWA_25739"
                font-size="26"
                class="off"
              />
            </div>
          </template>
        </div>
        <div
          class="share-banner__prize-text"
          :style="{ color: prizeStyle.color, bottom: prizeStyle.bottom }"
        >
          {{
            prizeType === 1
              ? shareLanguage.SHEIN_KEY_PWA_25530
              : shareLanguage.SHEIN_KEY_PWA_25742
          }}
        </div>
      </div>
    </div>
    <div class="share-banner__tip">
      {{
        shareStatus === 0
          ? shareLanguage.SHEIN_KEY_PWA_25734
          : shareLanguage.SHEIN_KEY_PWA_25740
      }}
    </div>
  </div>
</template>
<script>
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import OutlineText from './OutlineText.vue'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'ShareBanner',
  inject: ['context', 'extendData', 'shareLanguage'],
  components: {
    OutlineText,
    Icon,
  },
  props: {
    shareInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      images,
      outlineConfig: {
        outlineColor: '#FFD771',
        fillColor: '#FF700C',
        outlineSize: 1,
      },
    }
  },
  computed: {
    // 奖品信息
    prizeInfo() {
      const { prizeInfo = {} } = this.shareInfo
      return prizeInfo
    },
    // 用户中奖奖品记录
    userWinPrize() {
      const { userWinPrize = {} } = this.shareInfo
      return userWinPrize
    },
    // 分享状态 0:未分享 1:已分享
    shareStatus() {
      const { shareStatus = 0 } = this.shareInfo
      return Number(shareStatus)
    },
    // 奖品类型 1: 优惠券、2: 积分
    prizeType() {
      if (this.shareStatus) {
        return Number(this.userWinPrize?.prizeType || 1)
      } else {
        return Number(this.prizeInfo?.prizeType || 1)
      }
    },
    prizeStyle() {
      const imgs = {
        coupon: {
          backgroundImage: `url(${images.shareCoupons})`,
          color: '#AE6841',
          bottom: '0.4rem',
        },
        gotCoupon: {
          backgroundImage: `url(${images.gotCoupons})`,
          color: '#ffffff',
          bottom: '0.44rem',
        },
        point: {
          backgroundImage: `url(${images.sharePoints})`,
          color: '#AE6841',
          bottom: '0.4rem',
        },
        gotPoint: {
          backgroundImage: `url(${images.gotPoints})`,
          color: '#ffffff',
          bottom: '0.44rem',
        },
      }
      const { prizeType, shareStatus } = this
      if (prizeType === 1 && shareStatus === 0) {
        return imgs['coupon']
      } else if (prizeType === 1 && shareStatus === 1) {
        return imgs['gotCoupon']
      } else if (prizeType === 2 && shareStatus === 0) {
        return imgs['point']
      } else if (prizeType === 2 && shareStatus === 1) {
        return imgs['gotPoint']
      } else {
        return {}
      }
    },
    // 优惠券详情
    couponDetail() {
      if (this.shareStatus) {
        return JSON.parse(this.userWinPrize?.extendInfo || '{}')
      } else {
        return JSON.parse(this.prizeInfo?.extendInfo || '{}')
      }
    },
    // 优惠券类型
    couponType() {
      const { ruleDimension = '', applyFor = '' } = this.couponDetail || {}
      if (applyFor == '5' || applyFor == '6') {
        return 1 // 免邮券
      } else {
        if (ruleDimension == '1') return 2 // 满减券
        if (ruleDimension == '2' || ruleDimension == '5') return 3 // 折扣券
      }
      return ''
    },
    // 优惠券折扣值
    couponDiscountValue() {
      const { rule = [] } = this.couponDetail || {}
      if (this.couponType === 2) {
        // 满减券
        return rule?.[0]?.valueAmount?.amountWithSymbol || '0.00'
      } else if (this.couponType === 3) {
        // 折扣券
        return Number.parseInt(rule?.[0]?.value || '0') || 0
      }
      return ''
    },
  },
}
</script>
<style lang="less" scoped>
.share-banner {
  height: 4.1333rem;
  width: 8.7467rem;
  margin: 0.8rem auto 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 0.32rem;
  position: relative;
  &__got-bg {
    background: linear-gradient(270deg, #FFC657 0%, rgba(255, 198, 87, 0.00) 100%);
    border-radius: 0.2667rem;
    position: absolute;
    width: 100%;
    height: 88%;
    top: 0.1067rem;
    left: -0.2133rem;
  }
  &__got-icon {
    position: absolute;
    right: 0.3733rem;
    top: 0.2667rem;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.88rem;
  }
  &__user,
  &__prize {
    width: 2.4533rem;
    height: 2.4533rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
  }
  &__arrow {
    height: 1.8667rem;
    width: 1.2267rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__prize-coupon {
    color: #ff700c;
    // font-family: 'SF Pro Text';
    font-weight: 700;
    width: 1.5733rem;
    position: absolute;
    line-height: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 0.72rem;
    // 免邮券
    .free-shipping {
      text-align: left;
      line-height: 1.2;
    }
    // 满减券
    .fill-discount {
      padding: 0.0533rem 0.08rem 0;
      p {
        line-height: 1;
        padding: 0;
      }
      .amount {
        text-align: left;
      }
      .off {
        text-align: right;
        margin-top: 0.0533rem;
      }
    }
    // 折扣券
    .discount-coupon {
      padding: 0.0533rem 0.0533rem 0;
      .num {
        display: flex;
        align-items: baseline;
      }
      p {
        line-height: 0.8;
        padding: 0;
      }
      .off {
        text-align: right;
      }
    }
  }
  &__tip {
    color: #fff;
    text-align: center;
    // font-family: 'Roboto';
    font-size: 0.3733rem;
    text-transform: capitalize;
    margin-top: 0.1067rem;
    line-height: 1;
    padding: 0 0.2667rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 88%;
  }
  &__user-text,
  &__prize-text {
    color: #ae6841;
    text-align: center;
    // font-family: 'Roboto';
    font-size: 0.32rem;
    font-weight: 700;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
    position: absolute;
    width: 100%;
  }
  &__user-text {
    bottom: 0.4267rem;
  }
  &__prize-text {
    bottom: 0.4rem;
  }
}
</style>
