<template>
  <div class="dialog-coupon">
    <SingleCoupon
      v-if="couponListLength === 1 && !isOneMultiCoupon"
      :item="couponList[0]"
    />
    <div
      v-else
      class="dialog-coupon__list"
    >
      <!-- 只有一个和两个时必须包一层div -->
      <div class="dialog-coupon__list-wrap">
        <CouponItem
          v-for="(item, index) in couponList"
          :key="`${item}_${index}`"
          :item="item"
          :index="index"
          :coupon-list-length="couponList.length"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SingleCoupon from './SingleCoupon.vue'
import CouponItem from './CouponItem.vue'
export default {
  name: 'CouponDialogContent',
  components: {
    SingleCoupon,
    CouponItem,
  },
  props: {
    couponList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    couponListLength() {
      return this.couponList?.length || 0
    },
    isOneMultiCoupon() {
      if (this.couponListLength === 1) {
        const { rule = [] } = this.couponList[0]
        return rule?.length > 1
      }
      return false
    }
  },
}
</script>
<style lang="less" scoped>
.dialog-coupon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 100%;
  &__list {
    height: 5.8667rem;
    width: 7.5467rem;
    overflow: auto;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__list-wrap {
    width: 100%;
    overflow-x: hidden;
  }
}
</style>
