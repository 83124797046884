<template>
  <ClientOnly>
    <div
      class="leave-dialog"
      :class="{
        'show': show,
      }"
    >
      <s-sticky
        type="fixed"
        :offset="0.267"
        class="leave-dialog__back-placeholder"
      >
        <div
          class="leave-dialog__back-icon"
          @click="clickBackIcon()"
        ></div>
      </s-sticky>
      <LazyMount>
        <s-dialog
          :visible.sync="show"
          :show-close="false"
          :close-color="'#fff'"
          :modal="false"
          class="leave-dialog__wrap"
          @opened="handleOpened"
        >
          <div class="leave-dialog__bg">
            <BaseImg
              class="leave-dialog__bg-img"
              :first-screen="true"
              :is-support-webp="true"
              :img-src="images.lightImg"
            />
          </div>
          <template slot="title">
            <div
              class="leave-dialog__title"
            >
              <p
                ref="titleRef"
                class="leave-dialog__title-main"
              >
                {{ titleText }}
              </p>
              <p
                v-if="titleSubText"
                ref="subTitleRef"
                class="leave-dialog__title-sub"
              >
                {{ titleSubText }}
              </p>
            </div>
          </template>

          <div class="leave-dialog__content">
            <BaseImg
              class="leave-dialog__content-img"
              :first-screen="true"
              :ratio="beforeLeaveData?.image?.ratio || 1"
              :is-support-webp="true"
              :fit="'contain'"
              :img-src="beforeLeaveData?.image?.src"
              :placeholder="{
                width: beforeLeaveData?.image?.width,
                height: beforeLeaveData?.image?.height,
              }"
            />
            <Coupon
              v-if="prizeType === 2"
              :coupon-list="couponList"
            />
            <Point
              v-if="prizeType === 3"
              :point-exchange-price="pointExchangePrice"
              :point-info="pointInfo"
            />
          </div>

          <template
            slot="footer"
          >
            <s-button
              v-if="primaryBtnText"
              class="leave-dialog__primary-btn"
              :style="btnStyle"
              @click="handlePrimaryBtn()"
            >
              <div
                ref="primaryBtnRef"
                class="leave-dialog__primary-wrap"
              >
                {{ primaryBtnText }}
              </div>
            </s-button>
            <span
              class="leave-dialog__leave-btn"
              @click="clickLeave()"
            >
              {{ language.SHEIN_KEY_PWA_26136 }}
            </span>
            <Icon
              name="sui_icon_close_34px"
              size="34px"
              color="#fff"
              class="leave-dialog__close-icon"
              @click="closeDialog()"
            />
          </template>
        </s-dialog>
      </LazyMount>
    </div>
  </ClientOnly>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { LazyMount, Sticky as SSticky } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import Point from './components/Point.vue'
import Coupon from './components/Coupon.vue'
import { images } from './const'
import mixin from './mixin.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { preloadImgs } from '@shein/common-function'
import ClientOnly from 'vue-client-only'

export default {
  name: 'LeaveDialog',
  components: {
    ClientOnly,
    BaseImg,
    LazyMount,
    Icon,
    Point,
    Coupon,
    SSticky,
  },
  mixins: [mixin],
  props: {
    context: {
      type: Object,
      default: () => {},
    },
    cateLinks: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      images,
      couponList: [], // 优惠券详情list
      pointExchangePrice: '', // 积分兑换的价格
      availableCouponCodeList: [], // 可用的优惠券
      bindNum: 0, // 绑定次数
      unbindCouponCodeList: [], // 没有被绑定的优惠券
      dialogType: 'leave'
    }
  },
  provide() {
    return {
      language: this.dialogLanguage,
    }
  },
  computed: {
    // 挽留弹窗配置
    beforeLeaveData() {
      return this.modals?.beforeLeave || {}
    },
    // 弹窗公共配置
    modals() {
      const extend = this.context?.content?.extendParse
      return extend?.modals || {}
    },
    // 所有优惠券已经绑定
    isAllBind() {
      return !this.unbindCouponCodeList.length && this.availableCouponCodeList.length || this.leaveNoBindCoupon
    },
    // 弹窗副标题
    titleSubText() {
      const text = this.modals.langValueMap?.[this.titleTextSubLang || '']
      return this.isAllBind ? '' : text
    },
    // 弹窗副标题多语言key
    titleTextSubLang() {
      return this.modals.langKeyMap?.[this.beforeLeaveData.beforeLeaveModalLiteTitle?.textLangRefKey || '']?.pwa
    },
    // 弹窗标题
    titleText() {
      const text = this.modals.langValueMap?.[this.titleTextLang || ''] || this.language.SHEIN_KEY_PWA_26135
      return (this.prizeType === 2 && this.isAllBind) ? this.dialogLanguage.SHEIN_KEY_PWA_26542 : text
    },
    // 弹窗标题多语言key
    titleTextLang() {
      return this.modals.langKeyMap?.[this.beforeLeaveData.beforeLeaveModalTitle?.textLangRefKey || '']?.pwa
    },
    // 弹窗主按钮文案
    primaryBtnText() {
      let text = this.modals.langValueMap?.[this.primaryBtnTextLang || '']
      if ([2, 3].includes(this.prizeType)) {
        text = text || this.dialogLanguage.SHEIN_KEY_PWA_26114
      } else {
        text = text || this.language.SHEIN_KEY_PWA_26137
      }
      return (this.prizeType === 2 && this.isAllBind) ? this.dialogLanguage.SHEIN_KEY_PWA_26137 : text
    },
    // 弹窗主按钮文案多语言key
    primaryBtnTextLang() {
      return this.modals.langKeyMap?.[this.beforeLeaveData.primaryBtn?.textLangRefKey]?.pwa
    },
    // 弹窗类型 0-当前设备纬度下只弹出一次 1-总是弹起
    dialogPopType() {
      return this.beforeLeaveData.type
    },
    // 奖品类型：图文弹窗'1'，优惠卷弹窗'2'，积分弹窗'3'
    prizeType() {
      return Number(this.beforeLeaveData.modalType)
    },
    // 是否展示弹窗
    // enableLeaveDialog() {
    //   return this.beforeLeaveData.enable
    // },
    // 公共多语言
    language() {
      return this.context?.language || {}
    },
    // 弹窗多语言
    dialogLanguage() {
      const { entranceLeaveDialog = {} } = this?.context?.language || {}
      return entranceLeaveDialog
    },
    // 弹窗主按钮样式
    btnStyle() {
      const { color = '', backgroundColor = '' } = this.beforeLeaveData?.primaryBtn || {}
      return {
        color: color,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
      }
    },
    // 积分信息
    pointInfo() {
      const { prizeCode = '', prizeData = '', } = this.beforeLeaveData
      return {
        prizeCode,
        prizeData,
      }
    },
    // 优惠券code list
    couponCodeList() {
      const { couponItems = [], } = this.beforeLeaveData
      return couponItems.map(i => i.code?.toLowerCase())
    },
  },
  methods: {
    // 显示弹窗
    async showDialog() {
      if (this.prizeType === 2) { // 优惠券详情
        await this.queryCouponDetailAndStatus(isLogin() ? 'all' : 'detail')
        if (!this.couponList.length) {
          this.closePage()
          return
        }
      } else if (this.prizeType === 3) { // 积分兑换价格
        if (!this.pointInfo?.prizeData) {
          this.closePage()
          return
        }
        await this.getPointExchangePrice()
      }
      // 预加载背景图
      await preloadImgs({
        imgs: [
          this.beforeLeaveData.image?.src,
          this.lightImg,
        ]
      })
      await this.$nextTick()
      this.$emit('showLeaveDialog', true)
      this.show = true
    },
    // 点击返回按钮触发挽留弹窗
    clickBackIcon() {
      // 设备维度只弹一次
      if (this.dialogPopType === 0) {
        const key = `ccc-campaigns_leaveDialog_${this.context?.content?.identity}`
        const isExist = window.localStorage?.getItem(key)
        if (isExist) {
          this.closePage()
          return
        }
        window.localStorage.setItem(key, true)
        this.showDialog()
      }
      // 总是弹起
      if (this.dialogPopType === 1) {
        if (this.show) {
          this.closePage()
          return
        }
        this.showDialog()
      }
    },
    // 跳转链接
    handleJumpLink() {
      const { jumpType } = this.beforeLeaveData
      if (jumpType?.hrefTarget) {
        const url = this.cccLink.getFullLink({
          compIndex: 0,
          cateLinks: this.cateLinks,
          item: jumpType,
        })
        this.jumpLink({ url, hrefType: jumpType?.hrefType })
      } else {
        this._commonCloseFn('leave')
      }
    },
    // 点击离开按钮
    clickLeave() {
      this.commonAnalysis('leave')
      const { host = '', langPath = '', } = gbCommonInfo || {}
      location.href = `${host + langPath}/`
    },
    // 关闭页面,返回上一页
    closePage() {
      history.back()
    },
  },
}
</script>

<style lang="less">
  @zindex-hack-negative: -1;
  .leave-dialog {
    height: 0;
    overflow: hidden;
    &.show {
      height: auto;
      overflow: visible;
    }
    .leave-dialog {  // 线上环境会被直出的样式覆盖，需要提高层级
      &__wrap {
        background-color: rgba(0, 0, 0, 0.8);
      }
      &__back-placeholder {
        position: absolute;
        left: .32rem;
        top: -0.867rem;
        width: .64rem !important; /* stylelint-disable-line */
        height: .64rem !important; /* stylelint-disable-line */
        z-index: @zindex-trans-mask1 !important; /* stylelint-disable-line */
        &.S-sticky__fixed {
          padding-right: 0!important; /* stylelint-disable-line */
          padding-left: 0!important; /* stylelint-disable-line */
          padding-top: 0!important; /* stylelint-disable-line */
        }
      }
      &__back-icon {
        height: 100%;
      }
      &__bg {
        position: absolute;
        width: 17.973rem;
        left: -50%;
        top: calc(50% - 9rem); // - (背景高度(675px)-内容高度(100%))/2
        transform: translate(50%, 50%);
        z-index: @zindex-hack-negative;
        animation: leaveDialogTranslateBg 18s linear infinite;
      }
      &__bg-img {
        background: transparent;
      }
      &__header {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: .427rem;
      }
      &__title {
        color: #FFF;
        text-align: center;
        font-size: 0.587rem;
        font-weight: 700;
        line-height: 105%;
        margin-bottom: 0.32rem;
        text-align: center;
      }
      &__title-main {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-height: 1.2;
        width: 100%;
      }
      &__title-sub {
        font-size: 0.373rem;
        margin-top: 0.2667rem;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.2;
        width: 100%;
        font-weight: 300;
      }
      &__content {
        text-align: center;
        position: relative;
        img {
          width: 100%;
        }
      }
      &__content-img {
        background: transparent;
      }
      &__primary-btn {
        border-radius: 1.3333rem;
        height: 1.1467rem;
        max-width: 7.253rem;
        min-width: 5.0667rem;
        &.S-button.S-button__default {
          font-size: 0.5333rem;
          font-weight: 700;
          height: 1.2rem;
          line-height: normal;
          border-radius: 1.3333rem;
        }
      }
      &__primary-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      &__leave-btn {
        display: inline-block;
        margin-top: .427rem;
        width: 51%;  // 扩大蒙层点击区域
        color: #FFF;
        text-align: center;
        font-size: 0.4267rem;
        font-weight: 700;
        text-decoration: underline;
        line-height: 1;
      }
      &__close-icon {
        margin-top: 0.8rem;
        width: 50%;
      }
    }
    .S-dialog__ctn {
      .S-dialog__footer {
        margin-top: 0.64rem;
        padding: 0;
      }
      .S-dialog__body {
        overflow: visible;
        padding: 0;
      }
      .S-dialog__normal {
        max-height: 100%;
        width: calc(100% - 0.8533rem);
        max-width: calc(100% - 0.8533rem);
        background: none;
        box-shadow: none;
      }
    }
    .base-img__inner {
      left: 0;
    }
  }

  @keyframes leaveDialogTranslateBg {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
