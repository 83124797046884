import { template } from '@shein/common-function'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    couponListLength: {
      type: Number,
      default: 0
    },
  },
  inject: ['language'],
  computed: {
    ruleList() {
      const { rule = [] } = this.item || {}
      return rule
    },
    couponType() {
      const { ruleDimension = '', applyFor = '', rule = [] } = this.item || {}
      if (rule?.length > 1) {
        if (ruleDimension == '1') return -1 // 多档满减券
        if (ruleDimension == '2' || ruleDimension == '5') return 0 // 多档券折扣券
      }
      if (applyFor == '5' || applyFor == '6') {
        return 1 // 免邮券
      } else {
        if (ruleDimension == '1') return 2 // 满减券
        if (ruleDimension == '2' || ruleDimension == '5') return 3 // 折扣券
      }
    },
    couponDiscount() {
      const { rule = [] } = this.item || {}
      let discountValue = '' // 带货币符号的价格
      let noCurrencyPrice = ''  // 不带货币符号的价格
      let startSymbol = ''  // 货币符号在前
      let endSymbol = ''  // 货币符号在后
      // 门槛值
      const min = rule?.[0]?.minAmount?.amountWithSymbol || '0.00'
      if (this.couponType === 1) {
        // 免邮券
        discountValue = this.language.SHEIN_KEY_PWA_22801
      } else if ([-1, 2].includes(this.couponType)) {
        // 满减券
        discountValue = rule?.[0]?.valueAmount?.amountWithSymbol || '0.00'
        noCurrencyPrice = rule?.[0]?.valueAmount?.amount || '0.00'
        const { startSymbol: startSymbol1, endSymbol: endSymbol1 } = this.getPriceSymbol(discountValue)
        startSymbol = startSymbol1
        endSymbol = endSymbol1
      } else if ([0, 3].includes(this.couponType)) {
        // 折扣券
        discountValue = Number.parseInt(rule?.[0]?.value || '0')
      }
      return {
        discountValue,
        min: this.template(min, this.language.SHEIN_KEY_PWA_25736), // For Order Over xxx
        shortLanguageMin: this.template(min, this.language.SHEIN_KEY_PWA_26531), // Order Over xxx
        noCurrencyPrice,
        startSymbol,
        endSymbol
      }
    },
  },
  methods: {
    template,
    formatDiscountValue(value) {
      return Number.parseInt(value || '0')
    },
    getPriceSymbol(price = '') {
      const startSymbol = price?.match(/(^\D+)/)?.[0] || ''
      const endSymbol = price?.match(/(\D+)$/)?.[0] || ''
      return {
        startSymbol,
        endSymbol,
      }
    }
  },
}
