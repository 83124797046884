var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lottery-content"},[_c('div',{staticClass:"lottery-content__close-icon",on:{"click":_vm.handleClose}},[_c('Icon',{attrs:{"name":"sui_icon_close_20px","size":"20px","color":"#fff"}})],1),_vm._v(" "),_c('div',{staticClass:"lottery-content__title",style:({ backgroundImage: `url(${ _vm.images.lotteryTitleBg })` }),attrs:{"data-background-image":_vm.images.lotteryTitleBg}},[_c('OutlineText',{staticClass:"lottery-content__title-text",attrs:{"config":_vm.outlineConfig,"text":_vm.shareLanguage.SHEIN_KEY_PWA_28611,"font-size":"48","min-font-size":20,"is-scale-size":true}})],1),_vm._v(" "),_c('div',{staticClass:"lottery-content__prizes"},[_c('swiper-container',{ref:"prizesSwiper",class:['lottery-content__prizes-swiper', { 'center-layout': _vm.prizeList.length <= 3 }],attrs:{"init":"false"},nativeOn:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.prizeList),function(item){return _c('swiper-slide',{key:item.prizeId,staticClass:"lottery-content__prizes-slide"},[_c('BaseImg',{staticClass:"lottery-content__prizes-img",attrs:{"img-src":item.prizeImg,"placeholder":{
            width: 92,
            height: 92,
          },"new-ratio":1,"img-design-width":184,"fit":"cover","first-screen":false}}),_vm._v(" "),(_vm.isNumber(item.prizeLeftSum) && item.prizeLeftSum <= 0)?_c('div',{staticClass:"lottery-content__lottery-out",style:({ backgroundImage: `url(${ _vm.images.lotteryOutBg })` }),attrs:{"data-background-image":_vm.images.lotteryOutBg}},[_c('span',[_vm._v(_vm._s(_vm.shareLanguage.SHEIN_KEY_PWA_28614))])]):_vm._e()],1)}),1)],1),_vm._v(" "),(_vm.userWinnerRecordList.length)?_c('div',{staticClass:"lottery-content__user",style:({ backgroundImage: `url(${ _vm.images.lotteryUserBg })` }),attrs:{"data-background-image":_vm.images.lotteryUserBg}},[_c('swiper-container',{ref:"userWinRecordSwiper",staticClass:"lottery-content__user-container",attrs:{"init":"false"}},_vm._l((_vm.userWinnerRecordList),function(item,index){return _c('swiper-slide',{key:`${item.userName}_${index}`,staticClass:"lottery-content__user-slide"},[_c('img',{staticClass:"lottery-content__user-avatar",attrs:{"src":_vm.images.userAvatarIcon,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"lottery-content__user-text",domProps:{"innerHTML":_vm._s(_vm.template(item.userName, `<span class='prize-name'>${item.prizeName}</span>`, _vm.shareLanguage.SHEIN_KEY_PWA_28613))}})])}),1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"lottery-content__arrow"},[_c('BaseImg',{attrs:{"img-src":_vm.images.lotteryContentArrow,"placeholder":{
        width: 60,
        height: 30,
      },"img-design-width":120,"fit":"cover","first-screen":false}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }