import Vue from 'vue'
export const CloseIcon = Vue.extend({
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <circle cx="16" cy="16" r="15.2" stroke="white" stroke-width="1.6"/>
  <path d="M10.6666 10.6666L21.3333 21.3333" stroke="white" stroke-width="1.6" stroke-linecap="round"/>
  <path d="M21.3334 10.6666L10.6667 21.3333" stroke="white" stroke-width="1.6" stroke-linecap="round"/>
</svg>`
})

const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export const images = {
  facebook: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/facebook-logo-ebb6d469ef.png`,
  twitter: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/twitter-logo-39f24ffd8a.png`,
  instagram: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/ing-logo-d8bd676d04.png`,
  pinterest: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/pinterestLogo-edf1f398f7.png`,
  line: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/line-logo-3edd9a9e2b.png`,
  whatsapp: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/whatsapp-logo-a51b4b6fcb.png`,
  social: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/scoial-logo-abf408b381.png`,
  shareBgArrow: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/arrow-c2c4051c2f.png`,
  couponIcon: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/coupon-icon-3bf99e38f5.png`,
  goldIcon: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/gold-icon-e53310d135.png`,
  gotCoupons: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/got-coupons-7e6ef11bf9.png`,
  gotPoints: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/got-points-ebff9089d3.png`,
  likeIcon: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/like-icon-ed4efd359a.png`,
  likedIcon: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/liked-icon-afe3cbaa4d.png`,
  prizeBtn: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/prize-btn-169d454db9.png`,
  prizeDialogBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/prize-dialog-bg-e9c8218716.png`,
  shareCoupons: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/share-coupons-42b31655d6.png`,
  shareIcon: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/share-icon-70a311c2a0.png`,
  shareIconBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/share-icon-bg-4879c08900.png`,
  sharePoints: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/share-points-2187c6f209.png`,
  topAtmosphere: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/top-atmosphere-bd649aac3f.png`,
  userShare: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/user-share-da4d43442e.png`,
  shareAnimation: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/share-animation-46c80f07ac.webp`,
  shareAnimationGif: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/share-animation-gif-91cc3364ff.gif`,
  lotteryAtmosphere: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/lottery-atmosphere-3b01abc4d6.png`,
  lotteryDialogDefault: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/lottery-dialog-default-07487c903b.png`,
  lotteryDialogLarge: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/lottery-dialog-large-f475b4914d.png`,
  lotteryPrizeBtn: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/lottery-prize-btn-beaffd6020.png`,
  lotteryBannerBgBig: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/draw-banner-bg-big-216f1f86d6.png`,
  lotteryBannerBgSmall: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/draw-banner-bg-small-a4ce15791d.png`,
  lotteryContentArrow: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/draw-content-arrow-fe559af2f9.png`,
  lotteryTitleBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/draw-title-bg-94c6d46412.png`,
  lotteryUserBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/draw-user-bg-50f4412368.png`,
  userAvatarIcon: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/user-avatar-icon-3012a3b884.png`,
  lotteryOutBg: `${PUBLIC_CDN}/pwa_dist/images/ccc/share-and-like/draw-out-7870c01e01.png`,
}
