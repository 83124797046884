<template>
  <div class="share-channels">
    <ul
      v-if="snsList.length"
      class="share-channels__sns"
    >
      <li
        v-for="(item, index) in snsList"
        :key="index"
        v-tap="
          getAnalysisData('2-22-30', {
            sns_type: item.type,
            share_button_from: 1,
          })
        "
        class="share-channels__item"
        @click="handleSnsClick(item.channel)"
      >
        <div class="share-channels__item-icon">
          <Icon
            :name="item.logo"
            size="1.5467rem"
          />
        </div>
        <p class="share-channels__item-name">
          {{ item.name }}
        </p>
      </li>
    </ul>
    <ul
      v-if="systemList.length"
      class="share-channels__system"
    >
      <li
        v-for="(item, index) in systemList"
        :key="index"
        v-tap="
          getAnalysisData('2-22-30', {
            sns_type: item.type,
            share_button_from: 1,
          })
        "
        :class="[
          'share-channels__item',
          `share-channels__item-${item.channel}`,
        ]"
        @click="handleSystemClick(item.channel)"
      >
        <div class="share-channels__item-circle">
          <Icon
            :name="item.icon"
            size="1.5467rem"
          />
        </div>
        <p class="share-channels__item-name">
          {{ item.name }}
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
import { Toast } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

import { useClipboard } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import { getPageConfigShortLink } from 'public/src/services/api/ccc'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'

export default {
  name: 'ShareChannels',
  components: {
    Icon,
  },
  inject: ['sceneData', 'extendData', 'shareLanguage'],
  directives: {
    tap,
    expose,
  },
  mixins: [mixin],
  props: {
    snsList: {
      type: Array,
      default: () => [],
    },
    systemList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      snsLinkMap: {},
      shareParams: {},

      copyReady: false,
    }
  },
  async mounted() {
    await this.setShareParams()
    this.handleFinalLink()
  },
  methods: {
    handleSnsClick(channel) {
      this.$emit('snsClick', this.snsLinkMap[channel] || {})
    },
    handleSystemClick(channel) {
      if (channel === 'copyInviteLink') {
        this.handlerInviteLinkCopy(channel)
      } else if (channel === 'message') {
        const { title, url, description } = this.shareParams || {}
        const shareContent = encodeURIComponent(
          `${title}\n${description}\n${url}`
        )
        const result = {
          channel,
          result: 1,
        }
        this.$emit('systemClick', result)
        const isAndroid = navigator.userAgent?.toLowerCase().indexOf('android') > -1
        const linkSymbol = isAndroid ? 'sms:?' : 'sms:&'
        location.href = `${linkSymbol}body=${shareContent}`
      }
    },
    // 获取完整链接
    handleFinalLink() {
      if (!Array.isArray(this.snsList)) return
      const { title, description, img_url } = this.shareParams

      this.snsList.forEach(async (item) => {
        let shareUrl = `${location.origin}${location.pathname}?channel=${item.channel}`
        shareUrl = await this.createInviteTinyLink(shareUrl)
        let extendQuery = ''
        if (item.channel === 'pinterest') {
          extendQuery = `&media=${encodeURIComponent(
            img_url
          )}&description=${encodeURIComponent(
            description
          )}&title=${encodeURIComponent(title)}`
        }
        this.snsLinkMap[item.channel] = {
          ...item,
          url: `${item?.originUrl}${encodeURIComponent(
            shareUrl
          )}${extendQuery}`,
        }
      })
    },
    // 处理分享链接
    async setShareParams() {
      const { shareTitle, shareDesc, shareImgUrl = {} } = this.extendData
      const url = await this.createInviteTinyLink(location.href)
      let imgUrl = shareImgUrl?.src
      if (imgUrl.indexOf('//') === 0) {
        imgUrl = `https:${shareImgUrl}`
      }

      this.shareParams = {
        title: shareTitle,
        description: shareDesc,
        img_url: imgUrl,
        url,
      }
    },
    // 获取分享短链接
    async createInviteTinyLink(url) {
      let tinyInviteLink = ''
      const res = await getPageConfigShortLink({
        url: url || location.href,
      })
      const { code = '', data = {} } = res || {}
      if (code === '1000') {
        tinyInviteLink = data?.tinyUrl
      }
      return tinyInviteLink
    },
    // 复制分享链接
    handlerInviteLinkCopy(channel) {
      const { title, url, description } = this.shareParams || {}
      useClipboard('.share-channels__item-copyInviteLink', {
        text: `${title}\n${description}\n${url}`,
        onSuccess: () => {
          Toast({
            content: this.shareLanguage.SHEIN_KEY_PWA_21906,
            duration: 3000,
          })
          const result = {
            channel,
            result: 1,
          }
          this.$emit('systemClick', result)
        },
        onError: () => {
          Toast({
            content: this.shareLanguage.SHEIN_KEY_PWA_21907,
            duration: 3000,
          })
          const result = {
            channel,
            result: 0,
          }
          this.$emit('systemClick', result)
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.share-channels {
  margin: 0.6667rem 0.6933rem 0;
  &__system,
  &__sns {
    display: flex;
    overflow-x: auto;
  }
  &__sns {
    margin-bottom: 0.5333rem;
  }
  &__system {
    margin-bottom: 0.8rem;
  }
  &__item {
    width: 2.2133rem;
    flex-basis: 2.2133rem;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__item-icon {
    object-fit: contain;
    width: 1.5467rem;
    height: 1.5467rem;
    border-radius: 50%;
    background-color: #eeeeee;
  }
  &__item-circle {
    object-fit: contain;
    width: 1.5467rem;
    height: 1.5467rem;
    border-radius: 50%;
    background: #eeeeee;
  }
  &__item-name {
    color: #333;
    text-align: center;
    // font-family: 'Roboto';
    font-size: 0.32rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 0.2667rem;
    line-height: 1;
    padding-bottom: 1.9988px; // 兼容ipad
  }
}
</style>
