<template>
  <div
    v-if="show"
    :class="[
      'bottom-suspension',
      { 'have-countdown': showCountdown },
      { 'bottom-suspension-romwe': isRomwe },
    ]"
    :style="{ backgroundImage: `url(${ bgImage })` }"
  >
    <!-- 关闭icon -->
    <div
      class="bottom-suspension__close-icon"
      @click="handleClose"
    >
      <Icon
        name="sui_icon_close_20px"
        size="12px"
        color="#fff"
      />
    </div>
    <!-- 左边图片 -->
    <img
      :src="leftImage"
      alt=""
      class="bottom-suspension__left-icon"
    />
    <div class="bottom-suspension__discount">
      <div class="discount-content">
        <!-- 金额 -->
        <div
          class="discount-content__amount"
        >
          <!-- 单档满减券  && 多档满减券 -->
          <div
            v-if="[-1, 2].includes(couponType)"
            class="discount-content__fill"
          >
            <span
              v-if="couponDiscount.startSymbol"
              class="left-currency"
            >{{ couponDiscount.startSymbol }}</span>
            <span class="price">{{ couponDiscount.noCurrencyPrice }}</span>
            <span
              v-if="couponDiscount.endSymbol"
              class="right-currency"
            >{{ couponDiscount.endSymbol }}</span>
          </div>
          <!-- 折扣券 && 免邮券 -->
          <div
            v-else
            class="discount-content__free-discount"
            :style="{'font-size': couponType === 1 ? '0.3467rem' : '0.48rem' }"
          >
            {{ couponDiscount.discountValue }}
          </div>
        </div>
        <!-- % -->
        <p
          v-if="[0, 3].includes(couponType)"
          class="discount-content__percent"
        >
          %
        </p>
        <!-- off -->
        <p
          v-if="couponType !== 1"
          class="discount-content__off"
        >
          {{ language.SHEIN_KEY_PWA_25739 }}
        </p>
      </div>
      <!-- 门槛条件 -->
      <div
        :class="[
          'bottom-suspension__discount-min',
          {'min-ar': GB_cssRight}
        ]"
      >
        {{ couponDiscount.shortLanguageMin }}
      </div>
    </div>
    <!-- 倒计时 -->
    <Countdown
      v-if="showCountdown"
      :bind-time="+item?.couponBind?.start_time"
      :dynamic-expired-date="item?.dynamicExpiredDate"
      :end-time="item?.endTime"
      :start-time="item?.startTime"
      :is-romwe="isRomwe"
      class="bottom-suspension__countdown"
      @close="handleClose"
    />
  </div>
</template>

<script>
import couponMixin from './couponMixin'
import { images } from './const'
import { Icon } from '@shein-aidc/icon-vue2'
import Countdown from './components/Countdown.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
export default {
  name: 'ModalsBottomSuspension',
  components: {
    Icon,
    Countdown,
  },
  inject: ['GB_cssRight'],
  mixins: [couponMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'entrance'
    },
    item: {
      type: Object,
      default: () => {},
    },
    isRomwe: {
      type: Boolean,
      default: false
    },
    showBottomTab: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      images,
    }
  },
  computed: {
    showCountdown() {
      const { endTime = 0, dynamicExpiredDate = 0, couponBind = {}, } = this.item || {}
      const currentTime = Math.ceil(new Date().getTime() / 1000) // 转化成秒
      const countdownMaxTime = 72 * 60 * 60 // 72小时
      const dynamicTime = dynamicExpiredDate * 24 * 60 * 60
      const bindTime = +couponBind?.start_time || 0
      if (dynamicExpiredDate) {
        if (bindTime + dynamicTime - currentTime <= countdownMaxTime) return true
      } else {
        if (endTime - currentTime <= countdownMaxTime) return true
      }
      return false
    },
    leftImage() {
      if (this.ruleList.length > 1) {
        if (this.isRomwe) {
          return this.images.romweSuspensionMultiCoupon
        } else {
          return this.images.suspensionMultiCoupon
        }
      } else {
        if (this.isRomwe) {
          return this.images.romweSuspensionSingleCoupon
        } else {
          return this.images.suspensionSingleCoupon
        }
      }
    },
    bgImage() {
      if (this.showCountdown) {
        if (this.GB_cssRight) {
          return this.images.arCountdownSuspensionBg
        } else {
          return this.images.countdownSuspensionBg
        }
      } else {
        if (this.GB_cssRight) {
          return this.images.arSuspensionBg
        } else {
          return this.images.suspensionBg
        }
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        daEventCenter.triggerNotice({
          daId: '2-22-45',
          bindData: {
            code: this.item?.couponCode || '',
          }
        })
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit('close')
      daEventCenter.triggerNotice({
        daId: '2-22-46',
        bindData: {
          code: this.item?.couponCode || '',
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bottom-suspension {
  height: 1.2rem;
  width: 9.12rem;
  background-size: cover;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  // bottom: calc(var(--scoll-to-top-bottom) + 1.333rem);
  z-index: @zindex-returnTop;
  bottom: 0.4rem;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  align-items: center;
  box-shadow: 0 0 0.1067rem 0 rgba(0, 0, 0, 0.25);
  &__close-icon {
    position: absolute;
    right: -0.1867rem;
    top: -0.1867rem;
    width: 0.3733rem;
    height: 0.3733rem;
    background: rgba(0, 0, 0, .3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__left-icon {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    bottom: 0;
  }
  &__discount {
    margin-left: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 1.2;
    max-width: 7.28rem;
  }
  .discount-content {
    color: #9F4200;
    display: flex;
    margin-right: 0.1067rem;
    align-items: baseline;
    &__amount {
      font-weight: 700;
      font-size: 0.48rem;
    }
    &__fill {
      font-size: 0.3467rem;
      .price {
        font-size: 0.48rem;
      }
    }
    &__free-discount {
      font-size: 0.48rem;
    }
    &__percent {
      font-size: 0.48rem;
      font-weight: 700;
    }
    &__off {
      font-size: 0.3467rem;
      font-weight: 700;
      margin-left: 0.1067rem;
    }
  }
  &__discount-min {
    color: #9F4200;
    font-size: 0.2667rem;
    font-weight: 400;
    white-space: nowrap;
    direction: ltr /* rtl:ignore */;
  }
}
.have-countdown {
  .bottom-suspension__discount {
    width: 4.8rem;
    display: inline-block;
  }
  .min-ar {
    text-align: left;
  }
}
/* stylelint-disable selector-class-pattern */
// .show-bottom-safe-area {
//   bottom: 1.4667rem;
//   margin-bottom: constant(safe-area-inset-bottom);
//   margin-bottom: env(safe-area-inset-bottom);
// }
</style>
