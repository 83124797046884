<template>
  <p
    ref="textRef"
    class="outline-text"
    :style="{ fontSize: fontSizeRem, ...colorStyle }"
    v-html="text"
  ></p>
</template>

<script>
import { autoResponsiveFontSize } from 'public/src/pages/components/ccc/common/utils.js'
export default {
  name: 'OutlineText',
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    // 按750设计稿计算字体大小
    fontSize: {
      type: [String, Number],
      default: '18',
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    isScaleSize: {
      type: Boolean,
      default: false,
    },
    minFontSize: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    fontSizeRem() {
      return (Number(this.fontSize) / 750) * 10 + 'rem'
    },
    colorStyle() {
      const {
        outlineColor = '#FFD771',
        fillColor = '#9F4200',
        outlineSize = 3,
      } = this.config
      return {
        '-webkit-text-fill-color': fillColor,
        'background-color': outlineColor,
        '-webkit-text-stroke': `${outlineSize}px transparent`,
      }
    },
  },
  mounted () {
    if (this.isScaleSize) {
      autoResponsiveFontSize({
        containerEl: this.$refs?.['textRef'],
        textEl: this.$refs?.['textRef'],
        minFontSize: this.minFontSize,
      })
    }
  }
}
</script>
<style lang="less" scoped>
.outline-text {
  width: auto;
  margin: 0;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;
  position: relative;
  background-color: #ffd771;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #9f4200;
  -webkit-text-stroke: 3px transparent;
  padding: 2px; // 修复文字显示不全
  // font-family: 'SF Pro Text';
}
</style>
