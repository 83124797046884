<template>
  <LazyMount>
    <s-dialog
      :visible.sync="show"
      :show-close="false"
      class="entrance-dialog"
      :modal="false"
      @opened="handleOpened"
    >
      <div
        v-if="mountedData.mountedModalTitle?.textLangRefKey"
        slot="top"
        ref="titleRef"
        class="entrance-dialog__title"
      >
        {{ modalTitleText }}
      </div>
      <div class="entrance-dialog__bg">
        <BaseImg
          class="entrance-dialog__bg-img"
          :first-screen="true"
          :is-support-webp="true"
          :img-src="images.lightImg"
          @click.stop="handlePrimaryBtn()"
        />
      </div>
      <div class="entrance-dialog__content">
        <BaseImg
          class="entrance-dialog__content-img"
          :is-support-webp="true"
          :first-screen="true"
          :ratio="mountedData?.image?.ratio || 1"
          :fit="'contain'"
          :img-src="mountedData?.image?.src"
          :placeholder="{
            width: mountedData?.image?.width,
            height: mountedData?.image?.height,
          }"
        />
        <Coupon
          v-if="prizeType === 2"
          :coupon-list="couponList"
        />
        <Point
          v-if="prizeType === 3"
          :point-exchange-price="pointExchangePrice"
          :point-info="pointInfo"
        />
      </div>
      <template
        slot="footer"
      >
        <div @click.stop="handlePrimaryBtn()">
          <s-button
            v-if="mountedData.primaryBtn?.textLangRefKey"
            class="entrance-dialog__primary-btn"
            :style="btnStyle"
            @click.stop="handlePrimaryBtn()"
          >
            <div
              ref="primaryBtnRef"
              class="entrance-dialog__primary-wrap"
            >
              {{ primaryBtnText }}
            </div>
          </s-button>
          <Icon
            name="sui_icon_close_34px"
            size="34px"
            color="#fff"
            class="entrance-dialog__close-icon"
            @click.stop="closeDialog()"
          />
        </div>
      </template>
    </s-dialog>
  </LazyMount>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { LazyMount } from '@shein/sui-mobile'
import Point from './components/Point.vue'
import Coupon from './components/Coupon.vue'
import { images } from './const'
import { Icon } from '@shein-aidc/icon-vue2'
import mixin from './mixin.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { preloadImgs } from '@shein/common-function'

export default {
  name: 'EntranceDialog',
  components: {
    BaseImg,
    LazyMount,
    Point,
    Coupon,
    Icon,
  },
  mixins: [mixin],
  props: {
    context: {
      type: Object,
      default: () => {},
    },
    sceneData: {
      type: Object,
      default: () => {},
    },
    entranceData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      images,
      dialogType: 'entrance'
    }
  },
  provide() {
    return {
      language: this.dialogLanguage,
    }
  },
  computed: {
    // 钩子弹窗配置
    mountedData() {
      return this.modals?.mounted || {}
    },
    // 弹窗公共配置
    modals() {
      const extend = this.context?.content?.extendParse
      return extend?.modals || {}
    },
    // 主按钮文案
    primaryBtnText() {
      const text =  this.modals.langValueMap?.[this.primaryBtnTextLang || '']
      if ([2, 3].includes(this.prizeType)) {
        return text || this.dialogLanguage.SHEIN_KEY_PWA_26114
      } else {
        return text || this.language.SHEIN_KEY_PWA_21908
      }
    },
    // 主按钮文案多语言key
    primaryBtnTextLang() {
      return this.modals.langKeyMap?.[this.mountedData.primaryBtn?.textLangRefKey]?.pwa
    },
    // 弹窗标题文案
    modalTitleText() {
      return this.modals.langValueMap?.[this.modalTitleTextLang || '']
    },
    // 弹窗标题文案多语言key
    modalTitleTextLang() {
      return this.modals.langKeyMap?.[this.mountedData.mountedModalTitle?.textLangRefKey]?.pwa
    },
    // 弹窗类型 0-当前设备纬度下只弹出一次 1-总是弹起
    dialogPopType() {
      return this.mountedData.type
    },
    // 奖品类型：图文弹窗'1'，优惠券弹窗'2'，积分弹窗'3'
    prizeType() {
      return Number(this.mountedData.modalType)
    },
    // 是否展示图文弹窗
    showEntranceDialog() {
      return this.mountedData.enable && this.mountedData.image?.src
    },
    // 公共多语言
    language() {
      return this.context?.language || {}
    },
    // 弹窗多语言
    dialogLanguage() {
      const { entranceLeaveDialog = {} } = this?.context?.language || {}
      return entranceLeaveDialog
    },
    // 积分信息
    pointInfo() {
      const { prizeCode = '', prizeData = '', } = this.mountedData
      return {
        prizeCode,
        prizeData,
      }
    },
    // 优惠券code列表
    couponCodeList() {
      const { couponItems = [], } = this.mountedData
      return couponItems.map(i => i.code?.toLowerCase())
    },
    // btn样式
    btnStyle() {
      const { color = '', backgroundColor = '' } = this.mountedData?.primaryBtn || {}
      return {
        color: color,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
      }
    },
  },
  // watch: {
  //   entranceData: {
  //     async handler(val) {
  //       console.log(Object.keys(val).length, '=====val=====')
  //       if (Object.keys(val).length) {
  //         await this.handleCouponInfo(val)
  //       } else {
  //         await this.queryCouponDetailAndStatus(isLogin() ? 'all' : 'detail')
  //       }
  //       await this.$nextTick()
  //       this.openDialog()
  //     },
  //     deep: true,
  //   },
  // },
  async mounted() {
    // 积分兑换价格
    if (this.prizeType === 3) {
      await this.getPointExchangePrice()
    }
    // 优惠券详情
    if (this.prizeType === 2) {
      await this.queryCouponDetailAndStatus(isLogin() ? 'all' : 'detail')
    }
    await this.$nextTick()
    this.openDialog()
  },
  methods: {
    // 显示弹窗
    async showDialog() {
      if (this.prizeType === 2) {
        if (!this.couponList.length) return
      } else if (this.prizeType === 3) {
        if (!this.pointInfo.prizeData) return
      }
      if (isLogin() && this.prizeType === 2 && !this.unbindCouponCodeList.length) return
      // 预加载背景图
      await preloadImgs({
        imgs: [
          this.mountedData.image?.src,
          this.lightImg,
        ]
      })
      this.$emit('showEntranceDialog', true)
      this.show = true
    },
    // 打开弹窗
    openDialog() {
      if (!this.showEntranceDialog) return
      // 当前设备纬度下只弹出一次
      if (this.dialogPopType === 0) {
        const key = `ccc-campaigns_entranceDialog_${this.context?.content?.identity}`
        const isExist = window.localStorage?.getItem(key)
        if (isExist) return
        window.localStorage.setItem(key, true)
        this.showDialog()
        return
      }
      // 总是弹起
      if (this.dialogPopType === 1) {
        this.showDialog()
      }
    },
  },
}
</script>

<style lang="less">
  @zindex-hack-negative: -1;
  .entrance-dialog {
    background-color: rgba(0, 0, 0, 0.8);
    .entrance-dialog { // 线上环境会被直出的样式覆盖，需要提高层级
      &__title {
        color: #fff;
        font-size: 0.5867rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0.32rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-height: 1.2;
        width: 100%;
      }
      &__bg {
        position: absolute;
        width: 17.973rem;
        left: -50%;
        top: calc(50% - 9rem); // - (背景高度(675px)-内容高度(100%))/2
        transform: translate(50%, 50%);
        z-index: @zindex-hack-negative;
        animation: EntranceDialogTranslateBg 18s linear infinite;
      }
      &__bg-img {
        background: transparent;
      }
      &__header {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: .427rem;
      }
      &__content {
        text-align: center;
        position: relative;
        img {
          width: 100%;
        }
      }
      &__content-img {
        background: transparent;
      }
      &__primary-btn {
        border-radius: 1.3333rem;
        height: 1.1467rem;
        max-width: 7.253rem;
        min-width: 5.0667rem;
        &.S-button.S-button__default { /* stylelint-disable-line */
          font-size: 0.5333rem;
          font-weight: 700;
          height: 1.2rem;
          line-height: normal;
          border-radius: 1.3333rem;
        }
      }
      &__primary-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      &__close-icon {
        margin-top: 0.64rem;
        width: 50%;
      }
    }
    .S-dialog__ctn {
      .S-dialog__footer {
        padding: 0;
        margin-top: 0.64rem;
      }
      .S-dialog__body {
        overflow: visible;
        padding: 0;
      }
      .S-dialog__normal {
        max-height: 100%;
        width: calc(100% - 0.8533rem);
        max-width: calc(100% - 0.8533rem);
        background: none;
        box-shadow: none;
      }
    }
    .base-img__inner {
      left: 0;
    }
  }
  @keyframes EntranceDialogTranslateBg {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
