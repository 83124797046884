var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-channels"},[(_vm.snsList.length)?_c('ul',{staticClass:"share-channels__sns"},_vm._l((_vm.snsList),function(item,index){return _c('li',{directives:[{name:"tap",rawName:"v-tap",value:(
        _vm.getAnalysisData('2-22-30', {
          sns_type: item.type,
          share_button_from: 1,
        })
      ),expression:"\n        getAnalysisData('2-22-30', {\n          sns_type: item.type,\n          share_button_from: 1,\n        })\n      "}],key:index,staticClass:"share-channels__item",on:{"click":function($event){return _vm.handleSnsClick(item.channel)}}},[_c('div',{staticClass:"share-channels__item-icon"},[_c('Icon',{attrs:{"name":item.logo,"size":"1.5467rem"}})],1),_vm._v(" "),_c('p',{staticClass:"share-channels__item-name"},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")])])}),0):_vm._e(),_vm._v(" "),(_vm.systemList.length)?_c('ul',{staticClass:"share-channels__system"},_vm._l((_vm.systemList),function(item,index){return _c('li',{directives:[{name:"tap",rawName:"v-tap",value:(
        _vm.getAnalysisData('2-22-30', {
          sns_type: item.type,
          share_button_from: 1,
        })
      ),expression:"\n        getAnalysisData('2-22-30', {\n          sns_type: item.type,\n          share_button_from: 1,\n        })\n      "}],key:index,class:[
        'share-channels__item',
        `share-channels__item-${item.channel}`,
      ],on:{"click":function($event){return _vm.handleSystemClick(item.channel)}}},[_c('div',{staticClass:"share-channels__item-circle"},[_c('Icon',{attrs:{"name":item.icon,"size":"1.5467rem"}})],1),_vm._v(" "),_c('p',{staticClass:"share-channels__item-name"},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }