var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{class:[
    'bottom-suspension',
    { 'have-countdown': _vm.showCountdown },
    { 'bottom-suspension-romwe': _vm.isRomwe },
  ],style:({ backgroundImage: `url(${ _vm.bgImage })` })},[_c('div',{staticClass:"bottom-suspension__close-icon",on:{"click":_vm.handleClose}},[_c('Icon',{attrs:{"name":"sui_icon_close_20px","size":"12px","color":"#fff"}})],1),_vm._v(" "),_c('img',{staticClass:"bottom-suspension__left-icon",attrs:{"src":_vm.leftImage,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"bottom-suspension__discount"},[_c('div',{staticClass:"discount-content"},[_c('div',{staticClass:"discount-content__amount"},[([-1, 2].includes(_vm.couponType))?_c('div',{staticClass:"discount-content__fill"},[(_vm.couponDiscount.startSymbol)?_c('span',{staticClass:"left-currency"},[_vm._v(_vm._s(_vm.couponDiscount.startSymbol))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.couponDiscount.noCurrencyPrice))]),_vm._v(" "),(_vm.couponDiscount.endSymbol)?_c('span',{staticClass:"right-currency"},[_vm._v(_vm._s(_vm.couponDiscount.endSymbol))]):_vm._e()]):_c('div',{staticClass:"discount-content__free-discount",style:({'font-size': _vm.couponType === 1 ? '0.3467rem' : '0.48rem' })},[_vm._v("\n          "+_vm._s(_vm.couponDiscount.discountValue)+"\n        ")])]),_vm._v(" "),([0, 3].includes(_vm.couponType))?_c('p',{staticClass:"discount-content__percent"},[_vm._v("\n        %\n      ")]):_vm._e(),_vm._v(" "),(_vm.couponType !== 1)?_c('p',{staticClass:"discount-content__off"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_25739)+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{class:[
        'bottom-suspension__discount-min',
        {'min-ar': _vm.GB_cssRight}
      ]},[_vm._v("\n      "+_vm._s(_vm.couponDiscount.shortLanguageMin)+"\n    ")])]),_vm._v(" "),(_vm.showCountdown)?_c('Countdown',{staticClass:"bottom-suspension__countdown",attrs:{"bind-time":+_vm.item?.couponBind?.start_time,"dynamic-expired-date":_vm.item?.dynamicExpiredDate,"end-time":_vm.item?.endTime,"start-time":_vm.item?.startTime,"is-romwe":_vm.isRomwe},on:{"close":_vm.handleClose}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }