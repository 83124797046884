<template>
  <p
    class="outline-text"
    :style="{
      fontSize: fontSizeRem,
      ...colorStyle,
      lineHeight: lineHeightRem,
    }"
    v-html="text"
  ></p>
</template>

<script>
export default {
  name: 'OutlineText',
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    // 按750设计稿计算字体大小
    fontSize: {
      type: [String, Number],
      default: '18',
    },
    lineHeight: {
      type: [String, Number],
      default: 'initial',
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    fontSizeRem() {
      return this.formatRem(this.fontSize)
    },
    lineHeightRem() {
      if (!this.lineHeight) return
      return this.formatRem(this.lineHeight)
    },
    colorStyle() {
      const {
        outlineColor = '#FFD771',
        fillColor = '#9F4200',
        outlineSize = 3,
      } = this.config
      return {
        '-webkit-text-fill-color': fillColor,
        'background-color': outlineColor,
        '-webkit-text-stroke': `${outlineSize}px transparent`,
      }
    },
  },
  methods: {
    formatRem(size) {
      return (Number(size) / 750) * 10 + 'rem'
    }
  },
}
</script>
<style lang="less" scoped>
.outline-text {
  width: auto;
  margin: 0;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;
  position: relative;
  background-color: #ffd771;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #9f4200;
  -webkit-text-stroke: 3px transparent;
  padding: 2px; // 修复文字显示不全
  line-height: 1;
  filter: drop-shadow(0 0 1px transparent); // 修复ios上文字外框有一条白线的问题
  // font-family: 'SF Pro Text';
}
</style>
