<template>
  <LazyMount>
    <s-dialog
      ref="lotteryDialogRef"
      v-expose="getAnalysisData('2-22-31', prizeReportParams)"
      :visible.sync="show"
      :show-close="false"
      :append-to-body="true"
      class="lottery-dialog"
      @open="handleLotteryStart"
      @touchmove.native.prevent
    >
      <div slot="top"></div>
      <!-- 抽奖动画 -->
      <div 
        v-if="!showLotteryPrize && isShowVapText"
        class="lottery-dialog__vap-text"
      >
        <p>{{ shareLanguage.SHEIN_KEY_PWA_25558 }}</p>
        <p>{{ shareLanguage.SHEIN_KEY_PWA_20943 }}</p>
      </div>
      <div 
        v-if="!showLotteryPrize" 
        ref="lotteryVapRef"
        class="lottery-dialog__vap"
      ></div>
      <!-- 抽奖结果 -->
      <div 
        v-show="showLotteryPrize"
        class="lottery-dialog__wrapper"
      >
        <BaseImg
          :placeholder="{ width: 327, height: 138 }"
          :img-src="images?.lotteryAtmosphere"
          :img-design-width="654"
          :first-screen="show"
          class="lottery-dialog__atmosphere"
        />
        <div
          :class="['lottery-dialog__content', isCopyCard ? 'lottery-dialog__content-large' : '']"
          :style="{ backgroundImage: `url(${isCopyCard ? images.lotteryDialogLarge : images.lotteryDialogDefault})` }"
        >
          <p class="lottery-dialog__title">
            {{ prizeInfo?.prizeType === 5 ? shareLanguage.SHEIN_KEY_PWA_26143 : shareLanguage.SHEIN_KEY_PWA_25735 }}
          </p>
          <BaseImg
            :placeholder="{ width: 150, height: 150 }"
            :img-src="prizeExtendInfo?.prizeImg"
            :img-design-width="300"
            :first-screen="false"
            class="lottery-dialog__prize-img"
          />
          <!-- 礼品卡奖品 -->
          <CopyCard
            v-if="isCopyCard"
            :card-code="prizeExtendInfo?.voucherCode"
            :card-cipher="prizeExtendInfo?.voucherCipher"
            class="lottery-dialog__copy-card"
          />
          <p 
            class="lottery-dialog__tip" 
            @click="handleJump"
          >
            {{ lotteryTips }}
          </p>
        </div>
        <!-- 关闭按钮 -->
        <div
          ref="lotteryPrizeBtn"
          v-tap="getAnalysisData('2-22-32', prizeReportParams)"
          class="lottery-dialog__btn"
          :style="{ backgroundImage: `url(${images.lotteryPrizeBtn})` }"
          @click="closeDialog"
        >
          {{ shareLanguage.SHEIN_KEY_PWA_25743 }} {{ isCopyCard ? '' : `(${countdown}s)` }}
        </div>
        <div 
          class="lottery-dialog__close-icon"
          @click="closeDialog"
        >
          <Icon 
            name="sui_icon_close_12px_2" 
            size="0.5333rem"
            color="#fff" 
          />
        </div>
      </div>
    </s-dialog>
  </LazyMount>
</template>
<script>
import { Dialog, LazyMount } from '@shein/sui-mobile'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import CopyCard from './CopyCard.vue'

import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import { template, preloadImgs, transformImg } from '@shein/common-function'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'
import Vap from 'video-animation-player'
import vapConfig from 'public/pwa_dist/vap/share_and_like/vapc.json'

const { PUBLIC_CDN, langPath = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'LotteryDialog',
  inject: ['context', 'sceneData', 'extendData', 'shareLanguage'],
  components: {
    LazyMount,
    SDialog: Dialog,
    BaseImg,
    Icon,
    CopyCard,
  },
  directives: {
    tap,
    expose,
  },
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    prizeInfo: {
      type: Object,
      default: () => ({
        // prizeType: 5, // 奖品类型 1: 优惠券、2: 积分 12: 礼品卡 5: 谢谢惠顾
      }),
    },
    channel: {
      type: String,
      default: '',
    },
    vapConfig: {
      type: Object,
      default: () => vapConfig,
    },
    vapVideoUrl: {
      type: String,
      default: `${PUBLIC_CDN}/pwa_dist/vap/share_and_like/video.mp4`,
    }
  },
  data() {
    return {
      isShowVapText: false,
      countdown: 10,
      images,
      prizeReportParams: {},
      vap: null,
      showLotteryPrize: false,
    }
  },
  computed: {
    prizeExtendInfo() {
      const { prizeImg = '', voucherCode = '', voucherCipher = '' } = JSON.parse(this.prizeInfo?.extendInfo || '{}')
      return {
        prizeImg, // 奖品图片
        voucherCode, // 礼品卡卡号
        voucherCipher, // 礼品卡密码
      }
    },
    // 是否礼品卡奖品
    isCopyCard() {
      return Number(this.prizeInfo?.prizeType) === 12
    },
    lotteryTips() {
      const prizeType = this.prizeInfo?.prizeType
      const { SHEIN_KEY_PWA_25738, SHEIN_KEY_PWA_25737, SHEIN_KEY_PWA_28612 } = this.shareLanguage || {}
      return {
        1: SHEIN_KEY_PWA_25738 + ' >',
        2: SHEIN_KEY_PWA_25737 + ' >',
        12: SHEIN_KEY_PWA_28612 + ' >',
      }[prizeType]
    },
  },
  mounted() {
    // 预加载背景图
    const { lotteryAtmosphere, lotteryDialogDefault, lotteryDialogLarge } = this.images || {}
    preloadImgs({
      imgs: [lotteryAtmosphere, lotteryDialogDefault, lotteryDialogLarge],
    })
  },
  beforeDestroy() {
    this.vap?.destroy()
  },
  methods: {
    template,
    transformImg,
    handleLotteryStart() {
      this.getPrizeReportParams()
      this.$nextTick(() => {
        this.initAnimation()
      })
    },
    initAnimation() {
      this.vap = new Vap({
        container: this.$refs.lotteryVapRef,
        src: this.vapVideoUrl,
        config: this.vapConfig,
        fps: 25,
        width: window.screen.width * 4,
        height: (window.screen.width / 375) * 577 * 4,
        beginPoint: 0,
        accurate: true,
        loop: false,
        onLoadError: () => {
          this.showLotteryPrize = true
          // ...
        },
      })
      this.vap
        .on('frame', (frame) => {
          if (frame >= 3) this.isShowVapText = true
        })
        .on('ended', () => {
          this.showLotteryPrize = true
          !this.isCopyCard && this.handleCountdown()
          this.isShowVapText = false
          this.vap?.destroy()
        })
        .play()
    },
    // 10s倒计时
    handleCountdown() {
      const timer = setInterval(() => {
        if (this.countdown <= 1) {
          clearInterval(timer)
          this.closeDialog()
          return
        }
        this.countdown--
      }, 1000)
    },
    closeDialog() {
      this.$emit('close')
      this.showLotteryPrize = false
    },
    getPrizeReportParams() {
      const { prizeCode = '', prizeData = '', prizeType } = this.prizeInfo
      switch (prizeType) {
        case 1:
          this.prizeReportParams = {
            prize_type: `2_${prizeCode}`, // 埋点：1为积分 2为优惠券 3为礼品卡 4为谢谢惠顾
          }
          break
        case 2:
          this.prizeReportParams = {
            prize_type: `1_${prizeCode}_${prizeData}`,
          }
          break
        case 12:
          this.prizeReportParams = {
            prize_type: `3_${this.prizeExtendInfo?.voucherCode}`,
          }
          break
        case 5:
          this.prizeReportParams = {
            prize_type: '4',
          }
          break
        default:
          break
      }
    },
    handleJump() {
      this.closeDialog()
      const prizeType = this.prizeInfo?.prizeType
      // 优惠券列表
      if (prizeType === 1) this.$router.push(`${langPath}/user/coupon`)
      // 积分列表
      if (prizeType === 2) this.$router.push(`${langPath}/user/points`)
      // 礼品卡列表
      if (prizeType === 12) this.$router.push(`${langPath}/user/gift_card`)
    },
  },
}
</script>
<style lang="less" scoped>
@rem: 37.5rem;

.lottery-dialog {
  /deep/ .S-dialog__wrapper {
    background: transparent;
    box-shadow: none;

    .S-dialog__body {
      padding: 0 24 / @rem;
    }
  }

  /deep/ .S-dialog__normal {
    width: 100%;
    max-width: 100%;
    max-height: 608 / @rem;
  }

  &__vap {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: @zindex-out;
    width: 100%;
    transform: translateY(-50%);
    /deep/ canvas {
      width: 100%;
    }
  }

  &__vap-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 60 / @rem;
    padding: 0 16 / @rem;
    // font-family: Roboto;
    font-size: 24 / @rem;
    font-weight: 800;
    line-height: 28 / @rem;
    text-align: center;
    word-wrap: unset;
    font-style: italic;
    color: #FFFDA1;
    z-index: @zindex-header;
    transform: translate3d(-100%, -50%, 0);
    animation: 1.44s linear slidein forwards;

    @text-color: #DB212C;
    text-shadow: 1 / @rem 0 / @rem 1 / @rem @text-color,
      -1 / @rem 0 / @rem 1 / @rem @text-color, 0 / @rem 1 / @rem 1 / @rem @text-color,
      0 / @rem -1 / @rem 1 / @rem @text-color;

    @keyframes slidein {
      0% {
        transform: translate3d(-100%, -50%, 0);
      }
      9%, 80% {
        transform: translate3d(0, -50%, 0);
      }
      97%, 100% {
        transform: translate3d(100%, -50%, 0);
      }
    }
  }

  &__wrapper {
    animation: scaleShow 0.3s linear;
  }

  @keyframes scaleShow {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  &__atmosphere {
    width: 100%;

    .base-img__inner {
      padding-bottom: 0; // 覆盖弹窗中图片的padding-bottom
    }
  }

  &__title {
    margin-bottom: 9 / @rem;
    width: 100%;
    height: 48 / @rem;
    text-align: center;
    font-size: 20 / @rem;
    font-weight: 700;
    line-height: 48 / @rem;
    text-transform: capitalize;
    position: relative;
    color: #FFF;
  }

  &__content {
    .flexbox();
    .align-center();
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 265 / @rem;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  &__content-large {
    height: 348 / @rem;
  }

  &__prize-img {
    margin-bottom: 14 / @rem;
    width: 150 / @rem;
    height: 150 / @rem;
  }

  &__copy-card {
    margin-bottom: 16 / @rem;
    width: 266 / @rem;
    height: 57 / @rem;
  }
  
  &__tip {
    width: 100%;
    padding: 0 30 / @rem;
    color: #FF3D14;
    text-align: center;
    font-size: 12 / @rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__btn {
    margin: 24 / @rem auto 24 / @rem;
    color: #fff;
    text-align: center;
    font-size: 20 / @rem;
    font-weight: 700;
    height: 40 / @rem;
    width: 176 / @rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &__close-icon {
    .flexbox();
    .align-center();
    .pack-center();
    margin: 25 / @rem auto 0;
    width: 30 / @rem;
    height: 30 / @rem;
    border-radius: 999px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
