<template>
  <div
    :class="['coupon-item', { 'romwe-coupon-item': isRomwe }]"
    :style="{
      backgroundImage: `url(${isRomwe ? images.romweCouponItemBg : images.couponItemBg})`,
      marginBottom: [1, 2].includes(couponListLength) && index === couponListLength - 1 ? '0' : '0.2667rem',
    }"
  >
    <div class="coupon-item__content">
      <div
        v-if="![-1, 0].includes(couponType)"
        class="coupon-item__content-single"
      >
        <!-- 免邮券 -->
        <div class="single-top">
          <template v-if="couponType === 1">
            <div class="free-shipping">
              <p class="common-text">
                {{ couponDiscount.discountValue }}
              </p>
            </div>
          </template>
          <!-- 满减券 -->
          <template v-if="couponType === 2">
            <div :class="['full-discount', { 'direction-ar': GB_cssRight }]">
              <p class="common-text">
                -{{ couponDiscount.discountValue }}
              </p>
            </div>
          </template>
          <!-- 折扣券 -->
          <template v-if="couponType === 3">
            <div class="discount-coupon">
              <p class="p1 common-text">
                {{ couponDiscount.discountValue }}
              </p>
              <p class="p2 common-text">
                %
              </p>
              <p class="p3 common-text">
                {{ language.SHEIN_KEY_PWA_25739 }}
              </p>
            </div>
          </template>
        </div>
        <!-- 门槛条件 -->
        <div class="single-min">
          {{ couponDiscount.min }}
        </div>
      </div>
      <!-- 多档券 -1：满减券  0: 折扣券-->
      <div
        v-else
        class="coupon-item__content-multi"
        :style="{ columnCount: ruleList.length }"
      >
        <div
          v-for="(item, index) in ruleList"
          :key="`${item.couponId}_${index}`"
          class="coupon-item__content-item"
        >
          <div class="multi-wrap">
            <div class="multi-top">
              <!-- 满减券 -->
              <div
                v-if="couponType === -1"
                :ref="`fullDiscountRef${index}`"
                :class="['discount-num', 'full-discount', { 'direction-ar': GB_cssRight }]"
                :style="{'font-size': isShrinkMultiFullSize ? '0.32rem' : '0.5333rem'}"
              >
                -
                <span
                  v-if="getPriceSymbol(item.valueAmount.amountWithSymbol).startSymbol"
                  class="start-symbol currency"
                >
                  {{ getPriceSymbol(item.valueAmount.amountWithSymbol).startSymbol }}
                </span>
                <span
                  class="common-text"
                >
                  {{ item.valueAmount.amount }}
                </span>
                <span
                  v-if="getPriceSymbol(item.valueAmount.amountWithSymbol).endSymbol"
                  class="end-symbol currency"
                >
                  {{ getPriceSymbol(item.valueAmount.amountWithSymbol).endSymbol }}
                </span>
              </div>
              <div
                v-if="couponType === 0"
                class="discount-num"
              >
                <p class="num common-text">
                  {{ formatDiscountValue(item.value) }}
                </p>
                <p class="percent common-text">
                  %
                </p>
              </div>
              <!-- 折扣券 -->
              <p
                v-if="couponType === 0"
                :ref="`offRef${index}`"
                class="off common-text"
              >
                {{ getTextSize(language.SHEIN_KEY_PWA_25739, 'off') }}
              </p>
            </div>
            <div
              :ref="`multiMinRef${index}`"
              class="multi-min"
            >
              {{ template(item.minAmount.amountWithSymbol, language.SHEIN_KEY_PWA_26531) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { images } from './../const'
import { adjustFontSizeSingle } from 'public/src/pages/components/ccc/common/utils.js'
import couponMixin from '../couponMixin'
const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'CouponItemDialogContent',
  mixins: [couponMixin],
  inject: ['isRomwe'],
  data() {
    return {
      images,
      isShrinkMultiFullSize: false,
      GB_cssRight,
    }
  },
  async mounted() {
    this.$nextTick(() => {
      if (this.ruleList?.length > 1 && this.couponType === -1) {
        this.ruleList.forEach((item, index) => {
          const list = []
          const height = this.$refs[`fullDiscountRef${index}`]?.[0]?.offsetHeight || 0
          height && list.push(height)
          if (!list.length) {
            this.isShrinkMultiFullSize = true
          } else {
            this.isShrinkMultiFullSize = list.some(item => item > 25)
          }
        })
      }
    })
  },
  methods: {
    calcFontSize(text, type = 'minCondition') {
      this.$nextTick(() => {
        this.ruleList.forEach((item, index) => {
          // if (type === 'minCondition') {
          //   let minFontSize = 8
          //   if (this.ruleList.length === 2) {
          //     minFontSize = 10
          //   }
          //   autoResponsiveFontSize({
          //     containerEl: this.$refs[`multiMinRef${index}`]?.[0],
          //     textEl: this.$refs[`multiMinRef${index}`]?.[0],
          //     minFontSize,
          //     maxFontSize: 12,
          //   })
          // }
          if (type === 'off') {
            const width = this.$refs[`offRef${index}`]?.[0]?.offsetWidth
            let minFontSize = 10
            const wrapWidth = this.ruleList === 3 ? 33 : 79
            if (width < wrapWidth) {
              minFontSize = 12
            }
            adjustFontSizeSingle({
              containerEl: this.$refs[`offRef${index}`]?.[0],
              textEl: this.$refs[`offRef${index}`]?.[0],
              calcText: text,
              minFontSize,
            })
          }
          // if (type === 'fullDiscountAmount') {
          //   autoResponsiveFontSize({
          //     containerEl: this.$refs[`fullDiscountRef${index}`]?.[0],
          //     textEl: this.$refs[`fullDiscountRef${index}`]?.[0],
          //     // calcText: text,
          //     minFontSize: 12,
          //   })
          // }
        })
      })
    },
    getTextSize(text, type = 'minCondition') {
      this.calcFontSize(text, type)
      return text
    }
  },
}
</script>
<style lang="less" scoped>
/* stylelint-disable selector-max-specificity  */
.coupon-item {
  margin-bottom: 0.2667rem;
  height: 2.08rem;
  width: 100%;
  background-size: 100% 100%;
  color: #FA6338;
  .common-text {
    font-weight: 700;
  }
  .direction-ar {
    direction: rtl;
  }
  &__content{
    height: 100%;
    display: flex;
    align-items: center;
  }
  // 单档券
  &__content-single{
    width: 100%;
    padding-left: 0.4267rem;
    .single-top {
      height: 0.64rem;
      margin-bottom: 0.0533rem;
    }
    .single-min {
      font-size: 0.2667rem;
    }
    // 免邮券
    .free-shipping {
      .common-text {
        font-size: 0.5333rem;
      }
    }
    // 满减券
    .full-discount {
      .common-text {
        font-size: 0.5333rem;
      }
    }
    // 折扣券
    .discount-coupon {
      display: flex;
      align-items: baseline;
      .p1.common-text {
        font-size: 0.5333rem;
      }
      .p2.common-text {
        font-size: 0.32rem;
      }
      .p3.common-text {
        font-size: 0.32rem;
        margin-left: 0.0533rem;
      }
    }
  }
  // 多档券
  &__content-multi {
    column-count: 3;
    column-gap: auto;
    column-rule: 0.0267rem dashed #FA6338;
    margin: auto;
    padding: 0 0.4267rem;
    width: 100%;
  }
  &__content-item {
    .multi-top {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      .discount-num {
        display: flex;
        align-items: baseline;
        margin-right: 0.0533rem;
      }
      .full-discount {
        font-size: 0.5333rem;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
      }
      .num.common-text {
        font-size: 0.5333rem;
      }
      .percent.common-text {
        font-size: 0.32rem;
      }
      .off.common-text {
        font-size: 0.32rem;
        white-space: nowrap;
      }
    }
    .multi-min {
      margin-top: 0.1067rem;
      font-size: 0.2667rem;
    }
  }
}
.romwe-coupon-item {
  &.coupon-item {
    color: #FC4070;
  }
  .coupon-item__content-multi {
    column-rule: 0.0267rem dashed #FC4070;
  }
}
</style>
