
/* CCC重构 优惠券组件 */
import schttp from 'public/src/services/schttp'

export const getCccCouponLanguageApi = () => {
  return schttp({
    url: '/api/ccc/couponLanguage/get',
  })
}

export const getCccCouponDetailsApi = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/couponDetail/get',
    data
  })
}

export const bindCccCouponApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/ccc/bindCoupon/update`,
    data
  })
}

export const getCccCouponRestBindTimesApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/ccc/couponRestBindTimes/get`,
    data
  })
}

export const getCccCouponPromotionInfoApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/ccc/promotionById/get`,
    data
  })
}


// 获取用户优惠券列表数据 => 直接用的优惠券模块的接口
export const getMemberCouponListApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/coupon/memberCoupon/get`,
    data,
    needLogin: true
  })
}

// 查询优惠券绑定状态
export const queryCouponStatusApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/ccc/coupon/status/query`,
    data
  })
}

// 聚合查询优惠券列表和优惠券状态接口
export const queryCouponDetailAndStatusApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/ccc/coupon/detailAndStatus/query`,
    data
  })
}
