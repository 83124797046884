<template>
  <div class="lottery-content">
    <!-- 关闭按钮 -->
    <div
      class="lottery-content__close-icon"
      @click="handleClose"
    >
      <Icon
        name="sui_icon_close_20px"
        size="20px"
        color="#fff"
      />
    </div>
    <!-- title -->
    <div
      class="lottery-content__title"
      :data-background-image="images.lotteryTitleBg"
      :style="{ backgroundImage: `url(${ images.lotteryTitleBg })` }"
    >
      <OutlineText
        :config="outlineConfig"
        :text="shareLanguage.SHEIN_KEY_PWA_28611"
        font-size="48"
        :min-font-size="20"
        :is-scale-size="true"
        class="lottery-content__title-text"
      />
    </div>
    <!-- 奖品轮播区 -->
    <div class="lottery-content__prizes">
      <swiper-container
        ref="prizesSwiper"
        init="false"
        :class="['lottery-content__prizes-swiper', { 'center-layout': prizeList.length <= 3 }]"
        @click.native.stop
      >
        <swiper-slide
          v-for="item in prizeList"
          :key="item.prizeId"
          class="lottery-content__prizes-slide"
        >
          <BaseImg
            :img-src="item.prizeImg"
            :placeholder="{
              width: 92,
              height: 92,
            }"
            :new-ratio="1"
            :img-design-width="184"
            fit="cover"
            class="lottery-content__prizes-img"
            :first-screen="false"
          />
          <div
            v-if="isNumber(item.prizeLeftSum) && item.prizeLeftSum <= 0"
            class="lottery-content__lottery-out"
            :data-background-image="images.lotteryOutBg"
            :style="{ backgroundImage: `url(${ images.lotteryOutBg })` }"
          >
            <span>{{ shareLanguage.SHEIN_KEY_PWA_28614 }}</span>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <!-- 中奖名单轮播区 -->
    <div
      v-if="userWinnerRecordList.length"
      class="lottery-content__user"
      :data-background-image="images.lotteryUserBg"
      :style="{ backgroundImage: `url(${ images.lotteryUserBg })` }"
    >
      <swiper-container
        ref="userWinRecordSwiper"
        init="false"
        class="lottery-content__user-container"
      >
        <swiper-slide
          v-for="(item, index) in userWinnerRecordList"
          :key="`${item.userName}_${index}`"
          class="lottery-content__user-slide"
        >
          <!-- <BaseImg
            :img-src="images.userAvatarIcon"
            :placeholder="{
              width: 24,
              height: 24,
            }"
            :img-design-width="48"
            fit="cover"
            class="lottery-content__user-avatar"
          /> -->
          <img
            :src="images.userAvatarIcon"
            class="lottery-content__user-avatar"
            alt=""
          />
          <div
            class="lottery-content__user-text"
            v-html="template(item.userName, `<span class='prize-name'>${item.prizeName}</span>`, shareLanguage.SHEIN_KEY_PWA_28613)"
          >
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <!-- 箭头 -->
    <div class="lottery-content__arrow">
      <BaseImg
        :img-src="images.lotteryContentArrow"
        :placeholder="{
          width: 60,
          height: 30,
        }"
        :img-design-width="120"
        fit="cover"
        :first-screen="false"
      />
    </div>
  </div>
</template>

<script>
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import OutlineText from './OutlineText.vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import ExposeScroll from 'public/src/services/expose/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
import { template, isNumber } from '@shein/common-function'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()

export default {
  name: 'ShareLotteryContent',
  components: {
    OutlineText,
    BaseImg,
    Icon,
  },
  inject: ['shareLanguage'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    shareInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const vm = this
    return {
      images,
      outlineConfig: {
        outlineColor: '#DB212C',
        fillColor: '#FFFDA1',
        outlineSize: 1,
      },
      // 奖品列表swiper初始配置
      prizesOptions: {
        direction: 'horizontal',
        slidesPerView: 3.5,
        slidesPerGroup: 1,
        spaceBetween: 12,
        speed: 300,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
        on: {
          init() {
            if (!vm.viewPointObserve) {
              vm.viewPointObserve = new ExposeScroll({ exposeRatio: 0.9, delay: 500 })
              vm.$once('hook:beforeDestroy', () => vm.viewPointObserve?.destroy?.())
            }
            // init 函数内 this 指向 swiper 实例
            this.$el[0].swiperInstance = this
            console.log()
            vm.viewPointObserve.observe(
              {
                once: true,
                elements: { target: this.$el?.[0], code: 'autoplay' }
              },
              ({ exposeDoms }) => {
                exposeDoms?.forEach?.(dom => {
                  const swiperInstance = dom?.swiperInstance
                  if (!swiperInstance) return
                  const index = swiperInstance.slides?.length - 1
                  const num = swiperInstance.slides?.length - 3.5
                  if (num > 0) {
                    swiperInstance.slideTo(index, num * 2000, false)
                  }
                })
              }
            )
          }
        }
      },
      // 初始配置
      userWinRecordOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 1000,
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        allowTouchMove: false,
      },
    }
  },
  computed: {
    prizeList({ shareInfo }) {
      const { prizes = [] } = shareInfo?.prizePoolRuleBO || {}
      const notLotteryOutObjects = prizes.filter(obj => obj.prizeLeftSum > 0 || obj.prizeLeftSum === null)
      const lotteryOutObjects = prizes.filter(obj => isNumber(obj.prizeLeftSum) && obj.prizeLeftSum <= 0)
      return notLotteryOutObjects.concat(lotteryOutObjects)
    },
    userWinnerRecordList({ shareInfo }) {
      const { userWinPrizeList = [] } = shareInfo
      return userWinPrizeList
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          const swiper = this.$refs.userWinRecordSwiper.swiper
          swiper.loopDestroy()
          swiper.loopCreate()
          swiper.autoplay.start()
        }
      },
    },
  },
  async mounted(){
    const self = this
    setTimeout(() => {
      self.initSwiper()
    }, 1500)
  },
  methods: {
    template,
    isNumber,
    handleClose() {
      this.$emit('close')
    },
    initSwiper(){
      const prizesSwiperEl = this.$refs.prizesSwiper
      const prizesSwiperParams = {
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
          stopOnLastSlide: true,
        },
        direction: 'horizontal',
        slidesPerView: this.prizeList.length <= 3 ? 'auto' : 3.5,
        slidesPerGroup: 1,
        spaceBetween: this.prizeList.length <= 3 ? 0 : 12,
        speed: 300,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
        modules: [Autoplay],
        injectStyles: [
          this.prizeList.length <= 3 ? `
            .swiper-wrapper {
              justify-content: space-evenly;
            }
          ` : ''
        ],
      }
      Object.assign(prizesSwiperEl, prizesSwiperParams)
      prizesSwiperEl.initialize()

      const userWinRecordSwiperEl = this.$refs.userWinRecordSwiper
      const userWinRecordSwiperParams = {
        direction: 'vertical',
        autoplay: {
          delay: 1000,
          disableOnInteraction: false
        },
        loop: true,
        speed: 1000,
        slidesPerView: 1,
        allowTouchMove: false,
        modules: [Autoplay],
      }
      Object.assign(userWinRecordSwiperEl, userWinRecordSwiperParams)
      userWinRecordSwiperEl.initialize()
    }
  },
}
</script>

<style lang='less'>
.lottery-content {
  &__close-icon {
    width: 0.7467rem;
    height: 0.7467rem;
    background: rgba(0, 0, 0, .6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.24rem;
    margin-right: 0.1333rem;
    float: right;
  }
  &__title {
    height: 2.6133rem;
    width: 100%;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title-text {
    font-style: italic;
    line-height: 0.8rem;
    font-weight: 800;
    width: 80% !important;  /* stylelint-disable-line declaration-no-important */
  }
  &__prizes {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.4267rem 0 0.16rem;
    .swiper-wrapper.linear-mode {
      transition-timing-function: linear;
    }
  }
  &__prizes-swiper {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    &.center-layout {
      justify-content: space-evenly;
      padding: 0 0.32rem;
      .lottery-content__prizes-slide {
        margin: 0 auto;
      }
    }
  }
  &__prizes-slide{
    position: relative;
    --slides-per-view: 3.5;
    width: calc((100% - 12px * (var(--slides-per-view) - 1)) / var(--slides-per-view));
    margin-right: 12px;
    flex-shrink: 0;
  }
  &__lottery-out {
    position: absolute;
    background-size: cover;
    font-weight: 700;
    width: 1.4933rem;
    height: 1.4933rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: block;
      font-size: 0.2667rem;
      transform: rotate(-29.885deg);
      color: #fff;
      width: 50%;
      line-height: 1.2;
    }
  }
  &__prizes-img {
    height: 2.4533rem;
    width: 2.4533rem;
    flex: 1;
  }
  &__user {
    margin: 0 auto;
    height: 1.0133rem;
    width: 9.36rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
  }
  &__user-container {
    display: flex;
    height: 1.0133rem;
    flex-direction: column;
  }
  &__user-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.0133rem;
  }
  &__user-avatar {
    height: 0.64rem;
    width: 0.64rem !important;  /* stylelint-disable-line declaration-no-important */
    margin-right: 0.2133rem;
  }
  &__user-text {
    font-size: 0.32rem;
    color: #fff;
    font-weight: 400;
    display: flex;
    align-items: center;
    .prize-name {
      color: #FA6338;
      font-size: 0.3733rem;
      font-weight: 700;
      margin-left: 0.1067rem;
    }
  }
  &__arrow {
    width: 1.6267rem;
    height: 0.8rem;
    margin: 0.1067rem auto;
  }
}
</style>
