var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ClientOnly',[_c('div',{staticClass:"rules-dialog"},[_c('div',{staticClass:"rules-dialog__icon",class:{
        'rules-dialog__icon--left': _vm.rulesData.placement === 'left',
      },on:{"click":function($event){_vm.showDrawer = !_vm.showDrawer}}},[_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({id: '2-22-33'}),expression:"{id: '2-22-33'}"},{name:"tap",rawName:"v-tap",value:({id: '2-22-34'}),expression:"{id: '2-22-34'}"}],staticClass:"rules-dialog__icon-btn",style:({
          color: _vm.rulesData.color,
          backgroundColor: _vm.rulesData.backgroundColor,
          borderColor: _vm.rulesData.backgroundColor,
        })},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_14665)+"\n      ")])]),_vm._v(" "),_c('LazyMount',[_c('s-drawer',{attrs:{"visible":_vm.showDrawer,"direction":'btt',"type":'multi',"close-color":"#000","close-font-size":30},on:{"update:visible":function($event){_vm.showDrawer=$event}}},[_c('template',{slot:"top"},[_c('div',{staticClass:"rules-dialog__title"},[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_14665)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"rules-dialog__content"},_vm._l((_vm.rulesData.items),function(item,index){return _c('p',{key:index,staticClass:"rules-dialog__content-item"},[_vm._v("\n            "+_vm._s(_vm.rulesData.langValueMap?.[_vm.rulesData.langKeyMap?.[item.textLangRefKey]?.pwa] || '')+"\n          ")])}),0)],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }