<template>
  <div
    ref="shareRef"
    v-expose="getAnalysisData('2-22-25', shareReportParams)"
    class="ccc-share"
  >
    <div class="ccc-share__click">
      <div
        class="ccc-share__bg"
        :style="{
          backgroundImage: (showPrizeIdentify || showLotteryIdentify) ? `url(${images.shareIconBg})` : '',
        }"
      >
        <Animation v-if="(showPrizeIdentify || showLotteryIdentify)" />
        <div
          v-tap="getAnalysisData('2-22-26', shareReportParams)"
          class="ccc-share__circle"
          @click="openShareDrawer"
        >
          <div
            v-if="(showPrizeIdentify || showLotteryIdentify)"
            class="ccc-share__prizes-text"
          >
            {{ shareLanguage.SHEIN_KEY_PWA_25242 }}
          </div>
          <sui_icon_share_white
            size="0.96rem"
            color="#fff"
          />
        </div>
      </div>
    </div>
    
    <ShareDrawer
      :show="showShareDrawer"
      :share-info="shareInfo"
      :shareChannel="shareChannel"
      @close="closeShareDrawer"
      @shareResult="handleShareResult"
    />
    <PrizeDialog
      v-if="extendData?.shareType === '1'"
      :show="showPrizeDialog"
      :prize-info="prizeInfo"
      :channel="channel"
      @close="closePrizeDialog"
    />
    <LotteryDialog
      v-if="extendData?.shareType === '2'"
      :show="showPrizeDialog"
      :prize-info="prizeInfo"
      :channel="channel"
      @close="closePrizeDialog"
    />
  </div>
</template>
<script>
import { Toast } from '@shein/sui-mobile'
import ShareDrawer from './ShareDrawer.vue'
import PrizeDialog from './PrizeDialog.vue'
import LotteryDialog from './LotteryDialog.vue'
import Animation from './Animation.vue'
import { sui_icon_share_white } from '@shein-aidc/icon-vue2'

import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import {
  getPageConfigSharePrize,
  getPageConfigShareData,
} from 'public/src/services/api/ccc'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import ObExpose from 'public/src/services/expose/index.js'
import { isNumber } from '@shein/common-function'

// 极验接入
import GeeTest from 'public/src/pages/common/RiskCommon/libs/geetest/index.js'
import { loadTdLibJs } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'

export default {
  name: 'PageShare',
  inject: ['context', 'sceneData', 'extendData', 'shareLanguage'],
  components: {
    ShareDrawer,
    PrizeDialog,
    LotteryDialog,
    Animation,
    sui_icon_share_white,
  },
  directives: {
    tap,
    expose,
  },
  mixins: [mixin],
  data() {
    return {
      showShareDrawer: false,
      showPrizeDialog: false,
      showLotteryDialog: false,
      images,
      shareInfo: {},
      prizeInfo: {},
      channel: '',
      GeetestInstance: null,
      showLotteryIdentify: false,
      abtBranch: this.context?.content?.abtBranch || 'default',
    }
  },
  computed: {
    shareType() {
      const { shareType = '' } = this.extendData
      return  shareType
    },
    showPrizeIdentify() {
      return !this.isAllPrizeDrawOut
    },
    isPrizeShare() {
      // 分享类型 "0"- 基础分享 "1"- 有奖分享 "2"- 抽奖分享
      const { shareType = '' } = this.extendData
      return shareType === '1' || shareType === '2'
    },
    isGotPrize() {
      // 分享领奖状态 0:未分享 1:已分享
      return this.shareInfo?.shareStatus === 1
    },
    // 分享埋点参数
    shareReportParams() {
      return {
        share_type: Number(this.extendData?.shareType),
      }
    },
    shareChannel ({ extendData }) {
      if (extendData.shareChannel) {
        return extendData.shareChannel
      } else {
        // 旧数据兼容，保持之前的分享渠道和顺序
        return {
          firstLine: ['Twitter', 'Pinterest', 'Facebook'],
          secondLine: ['Copy Link', 'Message'],
        }
      }
    },
    // 判断奖品是否全部抽完
    isAllPrizeDrawOut() {
      let hidePrize = false
      if (this.shareType === '0') {
        hidePrize = true
      } else if (this.shareType === '1') {
        hidePrize = false
      } else  if (this.shareType === '2') {
        hidePrize = true
        // prizeLeftSum为空时表示不限制库存，只有等于0时表示奖品已抽完（类型5为谢谢惠顾视作已抽完）
        const { prizes = [] } = this.shareInfo?.prizePoolRuleBO || {}
        hidePrize = prizes?.every(item => item.prizeType === 5 || (isNumber(item.prizeLeftSum) && item.prizeLeftSum <= 0)) && !this.isGotPrize
      }
      return hidePrize
    },
  },
  mounted() {
    // 抽奖类型 需要风控极验
    this.isShareLottery = this.extendData?.shareType === '2'
    if (this.isShareLottery) {
      this.initGeeTest()
      loadTdLibJs() // 获取同盾参数
    }
    if (isLogin() && this.isPrizeShare) {
      this.fetchPageConfigShareData()
    }
    // 抽奖类型在没有登录的情况下默认显示prize文案和动画，isAllPrizeDrawOut调isLogin()会报错
    this.showLotteryIdentify = this.shareType === '2' && !isLogin()
  },
  methods: {
    openShareDrawer() {
      const loginCb = () => {
        this.fetchPageConfigShareData()
        this.showShareDrawer = true
      }
      if (!isLogin() && this.isPrizeShare) {
        const { id, name, identity } = this.context?.content || {}
        const scene = `campaigns_${identity}_${id}_${name}`

        SHEIN_LOGIN.show({
          show: true,
          sensors: scene,
          ga: scene,
          bi: scene,
          from: scene,
          cb: loginCb,
        })
        return
      }
      this.showShareDrawer = true
    },
    // 获取分享数据
    async fetchPageConfigShareData() {
      try {
        const res = await getPageConfigShareData({
          identity: this.context?.content?.identity || '',
          abtBranch: this.abtBranch,
        })
        this.shareInfo = res?.data || {}
      } catch (error) {
        console.log(error)
      }
    },
    closeShareDrawer() {
      this.showShareDrawer = false
    },
    openPrizeDialog() {
      this.showPrizeDialog = true
    },
    closePrizeDialog() {
      this.showPrizeDialog = false
    },
    async handleShareResult(shareResult) {
      const { result = 0, channel = '' } = shareResult || {}
      this.channel = channel
      const isCopyInviteLink = channel === 'copyInviteLink'
      if (!result) {
        return Toast({
          content: this.shareLanguage.SHEIN_KEY_PWA_25741,
          duration: 3000,
        })
      }
      // 有奖分享
      if (this.isPrizeShare && !this.isGotPrize) {
        // 抽奖类型&奖品全部抽完&非复制链接分享 提示
        if (this.isShareLottery && this.isAllPrizeDrawOut && !isCopyInviteLink) {
          Toast({
            content: this.shareLanguage.SHEIN_KEY_PWA_22145,
            duration: 3000,
          })
        }
        isCopyInviteLink ? this.handleShareSuccess() : this.ObShareSuccess()
      } else if (!isCopyInviteLink) {
        Toast({
          content: this.shareLanguage.SHEIN_KEY_PWA_22145,
          duration: 3000,
        })
      }
    },
    // 分享发奖
    async handleShareSuccess() {
      try {
        if (this.sharePrizeLock) return
        this.sharePrizeLock = true
        // 抽奖
        if (this.isShareLottery) {
          // 奖品全部抽完则不触发抽奖
          if (this.isAllPrizeDrawOut) {
            this.sharePrizeLock = false
            return
          }
          return this.handleShareLottery()
        }

        // 直接发奖
        const res = await getPageConfigSharePrize({
          identity: this.context?.content?.identity || '',
          abtBranch: this.abtBranch,
        })
        this.sharePrizeLock = false
        if (res?.code != '1000') {
          return this.handleSharePrizeError()
        }
        this.handleSharePrizeSuccess(res)
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    // 分享抽奖
    async handleShareLottery(geeParams) {
      try {
        const res = await getPageConfigSharePrize({
          identity: this.context?.content?.identity || '',
          risk_id: this.lotteryRiskId, // 触发回调携带该参数则告知接口极验通过
          blackbox: window._fmOpt?.__blackbox || '', // 同盾参数
          sm_device_id: window?._GB_DeviceFingerPrint?.smDeviceId || window?.SMSdk?.getDeviceId?.() || '', // 风控：数美设备id
          ...geeParams,
          abtBranch: this.abtBranch,
        })
        this.sharePrizeLock = false
        this.lotteryRiskId = ''
        if (res?.code != '1000') {
          return this.handleSharePrizeError()
        }
        const { geetestVerifiy = true, geetestVerificationType = '', riskId = '' } = res?.data || {}
        // 无需极验
        if (!geetestVerifiy) {
          return this.handleSharePrizeSuccess(res)
        }
        const geetestResult = await this.startGeeTest(geetestVerificationType)
        if (!geetestResult) {
          this.sharePrizeLock = false
          return
        } else {
          this.lotteryRiskId = riskId
          this.handleShareLottery(geetestResult)
        }
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    // 发奖接口错误提示语
    handleSharePrizeError() {
      const { SHEIN_KEY_PWA_24157, SHEIN_KEY_PWA_28616 } = this.shareLanguage || {}
      const { shareType = '' } = this.extendData
      return Toast({
        content: shareType === '2' ? SHEIN_KEY_PWA_28616 : SHEIN_KEY_PWA_24157, // 抽奖失败多语fail to draw
        duration: 3000,
      })
    },
    handleSharePrizeSuccess(res) {
      this.prizeInfo = res?.data || {}
      this.fetchPageConfigShareData()
      this.closeShareDrawer()
      this.openPrizeDialog()
    },
    async initGeeTest(){
      this.GeetestInstance = new GeeTest()
    },
    /**
     * 开始极验验证
     * @param {String} actionName 验证类型
     * @returns {Object||Boolean} 极验验证结果
     * 
     */
    async startGeeTest(actionName) {
      const _this = this
      return new Promise(function(resolve){
        const geetestWays = {
          1: 'icon',
          2: 'slide',
        }
        _this.GeetestInstance.register(async () => {
          const res = await _this.GeetestInstance.verifyPromise()
          if (res.status !== 'success') {
            resolve(false)
            return
          }
          resolve({
            challenge: res.challenge, // 极验流水号
          })
        }, geetestWays[actionName])
      })
    },
    // 监听分享成功后返回页面再触发发奖流程
    ObShareSuccess() {
      this.$nextTick(() => {
        this.obInstance = new ObExpose({ observeHide: true })
        this.obInstance.observe({
          elements: this.$refs?.shareRef,
        }, ({ exposeDoms }) => {
          if (exposeDoms.length) {
            // 进入视口
            this.handleShareSuccess()
            // 曝光的销毁
            this.obInstance?.destroy?.()
            this.obInstance = null
          }
        })
      })
      
    },
  },
}
</script>
<style lang="less" scoped>
.ccc-share {
  &__click {
    width: 1.7067rem;
    text-align: center;
    position: relative;
  }
  &__circle {
    position: relative;
    height: 0.96rem;
    width: 0.96rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    margin: auto auto 0.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__bg {
    .flexbox();
    .align-center();
    .pack-center();
    background-size: 100% 100%;
    width: 1.7067rem;
    height: 1.92rem;
    position: relative;
  }
  &__prizes-text {
    position: absolute;
    top: auto;
    bottom: -0.2667rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.72rem;
    border: 1px solid #fff;
    background: linear-gradient(221deg, #ff884a 0%, #fe3274 100%);
    height: 0.4267rem;
    min-width: 1.44rem;
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    // font-family: 'SF UI Display';
    font-size: 0.32rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    padding: 0 0.08rem;
  }
}
</style>
