<template>
  <teleport
    from="#ccc-campaigns"
    to="#app"
    :close-condition="closeCondition"
    :change-condition="changeCondition"
  >
    <div
      v-bottom-sticky="{
        isAllowFloat: true,
        styleType: 'BOTTOM_TAB',
      }"
      class="bottom-container"
    >
      <div
        ref="bottomTab"
        class="bottom-tab msapp-phone-padding"
      >
        <div
          v-if="tabList.length > 0"
          class="bottom-tab__nav-list"
        >
          <CccTabItem
            v-for="(tab, i) in tabList"
            :key="i"
            v-expose="getAnalysisData('2-22-23', handleAnalysisData(tab, i))"
            v-tap="getAnalysisData('2-22-24', handleAnalysisData(tab, i))"
            :style="{width: `${100 / (tabList.length)}%`}"
            :tab-item="tab"
            :current-tab-id="currentTabId"
            @clickTab="clickTab(tab)"
          />
        </div>
      </div>
      <div
        ref="bottomPlaceholder"
        class="bottom-placeholder msapp-phone-padding"
      ></div>
    </div>
  </teleport>
</template>

<script>
import TMG from '@shein/time-management-guru'
import { extractQueryString } from '@shein/common-function'
import { expose, tap } from 'public/src/pages/common/analysis/directive.js'
import { handleObjIsFrozen } from 'public/src/pages/components/ccc/common/utils.js'
import CccTabItem from './TabItem.vue'
import Teleport from './Teleport.vue'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { bottomSticky } from 'public/src/pages/components/ccc/base/sticky/bottom.js'

const scrollTop = {}

export default {
  name: 'CccBottomTab',
  components: {
    CccTabItem,
    Teleport,
  },
  directives: {
    expose,
    tap,
    bottomSticky,
  },
  mixins: [mixin],
  props: commonProps,
  data() {
    return {
      currentTabId: '',
    }
  },
  computed: {
    tabList() {
      let tabList = handleObjIsFrozen(this.context.content.bottomTabConfig || []) || []
      tabList = tabList.map(tab => {
        if (typeof tab.expandData === 'object') return tab
        try {
          tab.expandData = JSON.parse(tab.expandData)
          return tab
        } catch (error) {
          console.error(error)
        }
      }).filter(tab => !!tab)
      // 根据position排序
      tabList.sort((a, b) => a.expandData.position - b.expandData.position)
      return tabList
    }
  },
  created() {
    const { identity } = this.context.content
    this.currentTabId = identity
  },
  mounted() {
    this.setPlaceholderHeight()
    this.preloadPageData()
    this.scrollTop()
  },
  methods: {
    scrollTop() {
      const top = scrollTop[this.currentTabId]
      if (top) {
        setTimeout(() => {
          window.scrollTo(0, top)
        }, 20)
      }
    },
    closeCondition(router) {
      if (!router?.to) return true
      // 跳转的路由不在tab列表中，则关闭传送门
      return this.tabList.every(tab => {
        return router.to.path.indexOf(tab.identity) === -1
      })
    },
    changeCondition(newTabEl) {
      // 图片加载完成后再切换tab
      return function (resolve) {
        const lastImg = newTabEl.$el.querySelector('img:last-child')
        lastImg.onload = resolve
        lastImg.onerror = resolve
      }
    },
    preloadPageData() {
      this.tabList.forEach((tab) => {
        TMG.triggerQuickRequests('campaigns', {
          quickRequestNames: [`getCampaignsPageData/${tab.identity}`],
          throttle: true,
          triggerParams: { 
            identity: tab.identity, // 专题标识符
            tabIndex: 0,
            isLogin: typeof SHEIN_COMMON !== 'undefined' ? isLogin() : false
          }
        })
      })
    },
    async clickTab(tab) {
      if (this.currentTabId === tab.identity) return

      scrollTop[this.currentTabId] = document.documentElement.scrollTop || document.body.scrollTop
      if (this.currentTabId === this.tabList[0].identity) {
        this.openPage(tab.identity, 'push')
        return
      }

      this.currentTabId = tab.identity
      await this.$nextTick()

      this.openPage(tab.identity)
    },
    setPlaceholderHeight() {
      // 获取 bottomTab 的高度
      const bottomTabHeight = this.$refs.bottomTab.offsetHeight
      // 设置 bottomPlaceholder 的高度
      this.$refs.bottomPlaceholder.style.height = `${bottomTabHeight}px`
    },
    openPage(identity, method = 'replace') {
      const { langPath = '' } = window?.gbCommonInfo || {}
      this.$router[method](`${langPath}/campaigns/${identity}?${extractQueryString(location.href)}`)
    },
    handleAnalysisData(tab, index) {
      return {
        index,
        hrefType: 'bottomtab',
        hrefTarget: tab.identity,
        tab: [
          {
            index,
            cateId: 'activity',
            cateType: tab.identity,
            tabText: tab.expandData.tabMemo
          }
        ],
        content_list: tab.identity,
      }
    }
  },
}
</script>
<style lang="less">
  .bottom-tab {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: @zindex-header;
    &__nav-list {
      display: flex;
      border-top: 1px #e5e5e5 solid;
    }
  }
  .bottom-placeholder {
    background-color: @sui_color_gray_weak1a;
    min-height: 1.28rem;
  }
</style>
