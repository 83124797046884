<template>
  <div class="dialog-single-coupon">
    <div class="dialog-single-coupon__num">
      <OutlineText
        :text="couponDiscount.discountValue"
        font-size="48"
        line-height="40"
      />
      <OutlineText
        v-if="couponType === 3"
        text="%"
        font-size="48"
        line-height="40"
      />
      <!-- off -->
      <OutlineText
        v-if="couponType !== 1"
        :text="language.SHEIN_KEY_PWA_25739"
        font-size="48"
        line-height="40"
      />
    </div>
    <div class="dialog-single-coupon__condition">
      {{ couponDiscount.min }}
    </div>
  </div>
</template>
<script>
import OutlineText from 'public/src/pages/ccc-campaigns/components/common/OutlineText.vue'
import couponMixin from '../couponMixin'
export default {
  name: 'SingleCouponDialogContent',
  components: {
    OutlineText,
  },
  mixins: [couponMixin],
}
</script>
<style lang="less" scoped>
.dialog-single-coupon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  display: inline-block;
  width: 5.92rem;
  &__num {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: center;
  }
  &__condition {
    color: #9F4200;
    font-size: 0.3733rem;
    font-weight: 700;
    margin-top: 0.2667rem;
    text-align: center;
  }
}
</style>
